<template>
    <div class="mt-3 mb-3">
      <div class="carousel slide" id="carouselExampleIndicators" data-bs-ride="carousel">
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Icons"></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="icon-container">
              <img src="../../assets/img/certifs/polarized.png" class="icon" alt="Polarized">
              <img src="../../assets/img/certifs/cat3.png" class="icon" alt="Cat 3">
              <img src="../../assets/img/certifs/certif.png" class="icon" alt="Certif Quality">
              <img src="../../assets/img/certifs/hand-made.png" class="icon" alt="Hand made">
              <img src="../../assets/img/certifs/light-weight.png" class="icon" alt="light weight">
              <img src="../../assets/img/certifs/protection-uv-400.png" class="icon" alt="Protection uv 400">
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </template>
  
  <script>
  export default {
    name: 'CarouselComponent',
  };
  </script>
  
  <style scoped>
  .icon-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .icon {
    max-width: 100px;
    margin: 10px;
  }
  
  .list-group-item {
    border: none;
  }
  
  .list-icon {
    max-width: 50px;
  }
  </style>
  