<template>
    <ShopPage />
</template>
<script>
import ShopPage from '../components/shop/ShopPage.vue';
export default {
  name: 'ShopView',
  components:{
    ShopPage
  }
}
</script>