<template>
    <section class="about spad">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="about__pic">
                        <img src="../../assets/img/hero/men-4.jpg" alt="">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="about__item text-center">
                        <h4 class="">{{t('delivery_page_title')}}</h4>
                        <p>{{t('delivery_page_p1')}}</p>
                        <p>{{t('delivery_page_p2')}}</p>
                        <p>{{t('delivery_page_p3')}}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { useI18n } from 'vue-i18n';
export default {
    name: 'DeliveryPage',
    setup() {
      const { t } = useI18n();

      return {
        t
      };
    },
}
</script>