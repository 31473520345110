<template>
    <CartPage />
</template>
<script>
import CartPage from '../components/cart/CartPage.vue';

// @ is an alias to /src
CartPage
export default {
  name: 'CartView',
  components:{
    CartPage
  }
}
</script>