<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 ">
        <div class="checkout__order">
          <h4 class="order__title">LOGIN</h4>
          <div class="mb-3 register-info">
            <h5>If you don't have an account you can create account
              <b class="text-primary">
                <a href="/register">Register</a> </b>
            </h5>
          </div>
          <form @submit.prevent="handleLogin">
            <div class="row">
              <div class="col-lg-12">
                <div class="checkout__input">
                  <p>Email<span>*</span></p>
                  <input type="email" class="form-control" id="email" v-model="email" @blur="validateField('email')"
                    :class="{ 'is-invalid': errors.email }" placeholder="Email" required />
                  <div v-if="errors.email" class="invalid-feedback">{{ errors.email }}</div>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="checkout__input">
                  <p>Password<span>*</span></p>
                  <input type="password" class="form-control" id="password" v-model="password"
                    @blur="validateField('password')" :class="{ 'is-invalid': errors.password }" placeholder="Password" required />
                  <div v-if="errors.password" class="invalid-feedback">{{ errors.password }}</div>
                </div>
                <div class="col-lg-12 text-left mt-3 news-text">
                  <a href="/request-password-reset">Recovery password ?</a>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 text-center"><button type="submit" class="site-btn" :disabled="loading">LOGIN</button></div>
            </div>
            <div v-if="error" class="alert alert-danger mt-3">{{ error }}</div>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>
  
  <script>
  import { ref } from 'vue'
  import axios from 'axios'
  import { useRouter } from 'vue-router'
  import { validators } from '../../services/utils'
  import Swal from 'sweetalert2';
import { API_URL } from '@/services/apis'
  export default {
    setup() {
      const loading = ref(false)
      const email = ref('')
      const password = ref('')
      const error = ref(null)
      const errors = ref({})
      const router = useRouter()
  
      const validateField = (field) => {
        switch (field) {
          case 'email':
            errors.value.email = validators.required(email.value) === true ? '' : validators.required(email.value)
            if (!errors.value.email) {
              errors.value.email = validators.email(email.value) === true ? '' : validators.email(email.value)
            }
            break
          case 'password':
            errors.value.password = validators.required(password.value) === true ? '' : validators.required(password.value)
            break
        }
      }
  
      const handleLogin = async () => {
        loading.value = true;
        validateField('email')
        validateField('password')
  
        if (errors.value.email || errors.value.password) {
          loading.value = false;
          return
        }
  
        try {
          const response = await axios.post(API_URL+'/login/client', {
            email: email.value,
            password: password.value,
          })
  
          if (response.data.success) {
            Swal.fire({
              text: 'Welcome back !',
              icon: 'success'
            });
            localStorage.setItem('userData', JSON.stringify(response.data.data))
            localStorage.setItem('isConnected', 1)
            localStorage.setItem('token', response.data.data.token)
            router.push({ name: 'home' })
            error.value = null
            window.location.reload();
          } else {
            error.value = response.data.message || 'Login failed. Please try again.'
            localStorage.setItem('userData', JSON.stringify(response.data.data))
            localStorage.setItem('isConnected', 0)
            localStorage.setItem('token', '')
          }
        } catch (err) {
          const errResponse = err.response.data;
          console.log(errResponse.data.error)
          if (errResponse && errResponse.data.error === 'Invalid email') {
            errors.value.email = 'Something! Wrong Invalid Email';
            errors.value.password = '';
            error.value = 'Invalid email';
          } else if (errResponse && errResponse.data.error === 'Invalid password') {
            errors.value.password = 'Something! Wrong Invalid Password for this account!';
            errors.value.email = '';
            error.value = 'Invali Password';
          } else if(errResponse && errResponse.data.error === 'disabled'){
            error.value = 'Unauthorized. Your account has been disbaled ! contact Administration for more .. ! (contact@lynxlook.com)';
          }else {
            error.value = 'Invalid email or password';
          }
          console.error(err)
        }finally{
          loading.value = false;
        }
      }
  
      return {
        email,
        password,
        error,
        errors,
        validateField,
        handleLogin,
        loading
      }
    },
  }
  </script>
  
  <style scoped>
  .container {
    margin-top: 50px;
  }
  .is-invalid {
    border-color: #dc3545;
  }

.register-info h5{
  color:white !important;
}
.register-info a {
  text-decoration: none;
  
}
.checkout__input p{
  color:white !important;
}
.order__title{
  text-align: center;
  color:white !important;
}
.checkout__order{
  background-color: black;
}
.news-text{
  color:white;
}
.site-btn{
  background-color: white !important;
  color:black !important;
}
.site-btn:hover{
  background-color: black !important;
  color:white !important;
}
  </style>
  