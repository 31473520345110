<template>
    <CheckoutPage />
</template>
<script>
import CheckoutPage from '../components/checkout/CheckoutPage.vue';



// @ is an alias to /src

export default {
  name: 'CheckoutView',
  components:{
    CheckoutPage
  }
}
</script>