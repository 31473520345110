<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-6 ">
        <div class="checkout__order">
          <h4 class="order__title">Create New Customer Account</h4>
          <div class="mb-3 register-info">
            <h5>Please read our
              <b class="text-primary">
                <a href="/privacy-policy">Privacy Policy</a> </b> and <b class="text-danger">
                <a href="/terms-condition">Terms & Conditions</a> </b>
              before completing your account registration.
            </h5>
          </div>
          <form @submit.prevent="handleRegister">
            <div class="row">
              <div class="col-lg-12">
                <div class="checkout__input">
                  <p>Name<span>*</span></p>
                  <input type="text" class="form-control" id="name" v-model="name" @blur="validateField('name')"
                    :class="{ 'is-invalid': errors.name }" placeholder="Your Name"  required />
                  <div v-if="errors.name" class="invalid-feedback">{{ errors.name }}</div>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="checkout__input">
                  <p>Email<span>*</span></p>
                  <input type="email" class="form-control" id="email" v-model="email" @blur="validateField('email')"
                    :class="{ 'is-invalid': errors.email }" placeholder="Your Email" required />
                  <div v-if="errors.email" class="invalid-feedback">{{ errors.email }}</div>
                </div>
                <div class="col-lg-12">
                  <div class="checkout__input">
                    <p>Password<span>*</span></p>
                    <input type="password" class="form-control" id="password" v-model="password"
                      @blur="validateField('password')" :class="{ 'is-invalid': errors.password }" placeholder="Password" required />
                    <div v-if="errors.password" class="invalid-feedback">{{ errors.password }}</div>
                  </div>
                </div>
                <div class="col-lg-12 text-left mt-3 news-text">
                  <input type="checkbox" />
                  We would like to send you emails from time to time about our products, news or offers that we think you might be interested in. If you don’t want us to send you such emails, please untick this box.
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 text-center"><button type="submit" class="site-btn" :disabled="loading">CREATE AN ACCOUNT</button></div>
            </div>
            <div v-if="error" class="alert alert-danger mt-3">{{ error }}</div>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { ref } from 'vue'
import axios from 'axios'
import { validators } from '../../services/utils'
import { useRouter } from 'vue-router'
import Swal from 'sweetalert2';
import { API_URL } from '@/services/apis'
export default {
  setup() {
    const loading = ref(false)
    const name = ref('')
    const email = ref('')
    const password = ref('')
    const error = ref(null)
    const errors = ref({})
    const router = useRouter()
    const validateField = (field) => {
      switch (field) {
        case 'name':
          errors.value.name = validators.required(name.value) === true ? '' : validators.required(name.value)
          break
        case 'email':
          errors.value.email = validators.required(email.value) === true ? '' : validators.required(email.value)
          if (!errors.value.email) {
            errors.value.email = validators.email(email.value) === true ? '' : validators.email(email.value)
          }
          break
        case 'password':
          errors.value.password = validators.required(password.value) === true ? '' : validators.required(password.value)
          break
      }
    }

    const handleRegister = async () => {
      loading.value = true;
      validateField('name')
      validateField('email')
      validateField('password')

      if (errors.value.name || errors.value.email || errors.value.password) {
        loading.value = false;
        return
      }

      try {
        const response = await axios.post(API_URL+'/register', {
          name: name.value,
          email: email.value,
          password: password.value,
        })
        // Handle successful registration (e.g., store token, redirect, etc.)
        console.log(response.data)
        if (response.data.success) {
          Swal.fire({
            text: 'Welcome',
            icon: 'success'
          });
          localStorage.setItem('userData', JSON.stringify(response.data.data))
          localStorage.setItem('isConnected', 1)
          localStorage.setItem('token', response.data.data.token)
          router.push({ name: 'home' })
          error.value = null
          window.location.reload();
        }
        error.value = null
      } catch (err) {
        error.value = 'Failed to register. Please try again.'
        console.error(err)
      } finally{
        loading.value = false;
      }
    }

    return {
      name,
      email,
      password,
      error,
      errors,
      validateField,
      handleRegister,
      loading
    }
  },
}
</script>

<style scoped>
.container {
  margin-top: 50px;
}

.is-invalid {
  border-color: #dc3545;
}
.register-info h5{
  color:white !important;
}
.register-info a {
  text-decoration: none;
  
}
.checkout__input p{
  color:white !important;
}
.order__title{
  text-align: center;
  color:white !important;
}
.checkout__order{
  background-color: black;
}
.news-text{
  color:white;
}
.site-btn{
  background-color: white !important;
  color:black !important;
}
.site-btn:hover{
  background-color: black !important;
  color:white !important;
}
</style>