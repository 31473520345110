<template>
    <ProfilePage />
</template>

<script>
import ProfilePage from '../components/profile/ProfilePage.vue';
export default {
    name: 'ProfileView',
    components: {
        ProfilePage
    }
}
</script>
<style scoped></style>