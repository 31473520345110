<template>
  <AboutPage />
</template>
<script>
import AboutPage from '@/components/pages/AboutPage.vue'

export default {
name: 'ContactView',
components:{
  AboutPage
}
}
</script>