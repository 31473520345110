<template>
    <WhishlistPage />
</template>
<script>
import WhishlistPage from '../components/whishlist/WhishlistPage.vue';
// @ is an alias to /src
export default {
  name: 'WhishlistView',
  components:{
    WhishlistPage
  }
}
</script>