<template>

    <section class="checkout spad">
        <div class="container">
            <div class="checkout__form">
                <div class="col-lg-8">
                    <div class="card" v-if="isConnected == 1">

                        <div class="card-body">
                            <h5 class="card-title">Hello, {{ userData.user.name }}</h5>
                            <p class="card-text">You can automatically fill the form of BILLING DETAILS below with your
                                current address by
                                clicking the button.<br />
                                <strong>Note:</strong> You can also <a href="/profile" class="text-danger">create your
                                    own default address</a> if you prefer.
                            </p>
                            <p>{{ userData.detail?.adresse }} {{ userData.detail?.adrese_complete }}
                                | {{ userData.user.email }} - {{ userData.detail?.phone }}</p>
                            <div class="text-right"><a class="site-btn-fill-data" @click="fileUserDataToForm()">Use My
                                    Current Address</a></div>
                        </div>
                    </div>
                </div>

                <form @submit.prevent="placeOrderCheck">
                    <div class="row">
                        <div class="col-lg-8 col-md-6">
                            <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
                                <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
                                    <path
                                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </symbol>
                                <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
                                    <path
                                        d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                                </symbol>
                                <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
                                    <path
                                        d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                </symbol>
                            </svg>
                            <div class="alert alert-warning d-flex align-items-center" role="alert"
                                style="background-color: #F3F2EE;">

                                <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img"
                                    aria-label="Warning:">
                                    <use xlink:href="#exclamation-triangle-fill" />
                                </svg>
                                <h6 class="text-muted">{{$t('All fieled with')}} <b class="text-danger">(*)</b>
                                    {{$t('are required')}}</h6>



                            </div>


                            <div v-if="errorMessages.length" class="alert alert-danger" role="alert">
                                <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img"
                                    aria-label="Warning:">
                                    <use xlink:href="#exclamation-triangle-fill" />
                                </svg>
                                <ul>
                                    <span v-for="(message, index) in errorMessages" :key="index">
                                        <i class="bi bi-exclamation-circle-fill"></i> {{ message }}<br>
                                    </span>
                                </ul>
                                <button type="button" class="btn-close" data-bs-dismiss="alert"
                                    aria-label="Close"></button>
                            </div>
                            <h6 class="checkout__title">{{$t('Billing')}} {{$t('Details')}}</h6>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="checkout__input">
                                        <p>{{$t('First Name')}}<span>*</span></p>
                                        <input type="text" v-model="billingData.name" required>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="checkout__input">
                                        <p>{{$t('Last Name')}}<span>*</span></p>
                                        <input type="text" v-model="billingData.surnom" required>
                                    </div>
                                </div>
                            </div>
                            <div class="checkout__input">
                                <p>{{$t('City')}}<span>*</span></p>
                                <input type="text" v-model="billingData.ville" required>
                            </div>
                            <div class="checkout__input">
                                <p>{{$t('Address')}}<span>*</span></p>
                                <input type="text" placeholder="Street Address" class="checkout__input__add"
                                    v-model="billingData.adresse" required>
                                <input type="text" placeholder="Apartment, suite, unite ect (optinal)"
                                    v-model="billingData.adrese_complete">
                            </div>
                            <div class="checkout__input">
                                <p>{{$t('Postcode')}} / {{$t('ZIP')}}<span>*</span></p>
                                <input type="text" v-model="billingData.code_postal" required>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="checkout__input">
                                        <p>{{$t('Phone')}}<span>*</span></p>
                                        <input type="text" v-model="billingData.phone" required>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="checkout__input">
                                        <p>{{$t('Email')}}<span>*</span></p>
                                        <input type="email" v-model="billingData.email" required>
                                    </div>
                                </div>
                            </div>
                            <div class="checkout__input__checkbox" v-if="isConnected === '0'">
                                <label for="acc">
                                    {{$t('Create an account')}}? <span class="text-muted">
                                        {{$t('If you are aleardy have an account')}} <a class="signin-link"
                                            href="/login">SIGN IN</a></span>
                                    <input type="checkbox" id="acc" v-model="billingData.is_registred">
                                    <span class="checkmark"></span>
                                </label>
                                <p>{{$t('Create an account by entering the information below')}}.</p>

                            </div>

                            <div class="checkout__input" v-if="isConnected === '0'">
                                <p>{{$t('Account Password')}}<span>*</span></p>
                                <input type="password" v-model="billingData.password">
                            </div>
                            <div class="checkout__input__checkbox">
                                <label for="diff-acc">
                                    {{$t('Note about your order')}}, e.g,{{$t('special notes for delivery')}}
                                    <input type="checkbox" id="diff-acc">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="checkout__input">
                                <p>{{$t('Notes')}}<span>*</span></p>
                                <input type="text"
                                    placeholder="Notes about your order, e.g. special notes for delivery."
                                    v-model="billingData.note">
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="cart__discount" v-if="isConnected == 1">
                                <div v-if="errorMessagesCoupon?.length" class="alert alert-danger" role="alert">
                                    <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img"
                                        aria-label="Warning:">
                                        <use xlink:href="#exclamation-triangle-fill" />
                                    </svg>
                                    <ul>
                                        <span v-for="(message, index) in errorMessagesCoupon" :key="index">
                                            <i class="bi bi-exclamation-circle-fill"></i> {{ message }}<br>
                                        </span>
                                    </ul>
                                    <button type="button" class="btn-close" data-bs-dismiss="alert"
                                        aria-label="Close"></button>
                                </div>
                                <div v-if="errorText > 0">
                                    <span class="text-danger" v-if="errorText == 1">Coupon Invalid</span>
                                    <span class="text-danger" v-if="errorText == 2">Copon aleardy used</span>
                                    <span class="text-danger" v-if="errorText == 3">Something went Wrong ! Try
                                        again</span>
                                    <span class="text-success" v-if="errorText == 4">Coupon added ^^</span>
                                </div>
                                <form @submit.prevent="couponCheck">
                                    <input type="text" placeholder="Coupon code" class="form-control" name="coupon" v-model="couponValueData.coupon">
                                    <button type="submit" class="btn btn-primary">Apply</button>
                                </form>
                            </div>
                            <div class="cart__coupon" v-else>
                                Do you have a coupon code? <a href="/login">SIGN IN</a> to use it!
                            </div>

                            <div class="checkout__order">
                                <h4 class="order__title">{{$t('Your order')}}</h4>
                                <div class="checkout__order__products">{{$t('Product')}} <span>{{$t('Total')}}</span>
                                </div>
                                <ul class="checkout__total__products" v-for="(item, index) in cartItems" :key="index">
                                    <li>{{ index + 1 }}. {{ item.name }} <span> {{ item.prix * item.quantity }}
                                            DH</span>
                                    </li>
                                    <li v-if="item.selectedOptions">
                                        Options :
                                        <span v-for="(option, optionId) in item.selectedOptions" :key="optionId">
                                            <b class="item-option">
                                                {{ getOptionName(optionId) }} - {{ optionId }} : {{
                                                getOptionValueName(option.optionValeurId) }}
                                                - {{option.optionValeurId}}
                                            </b>
                                        </span>
                                    </li>
                                </ul>
                                <ul class="checkout__total__all">
                                    <li>{{$t('Subtotal')}} <span>{{ cartTotal }} DH</span></li>
                                    <li v-if="discountCoupon.discount > 0 && errorText == 4">{{ $t('Discount') }}<span>{{ discountCoupon.discount }} %</span> </li>
                                    <li >{{$t('Total')}} 
                                        <span v-if="discountCoupon.discount > 0 && errorText == 4">{{ cartTotal - (cartTotal * (discountCoupon.discount/100)) }} DH</span>
                                        <span v-else>{{ cartTotal }} DH</span>
                                    </li>
                                    <!--<li>{{$t('Total')}} <span>{{ cartTotal }} DH</span></li>-->
                                </ul>

                                <b style="color: black;">{{$t('Payment options')}}</b>
                                <div class="checkout__input__checkbox">
                                    <label for="payment">
                                        <span>{{$t('Cash on delivery(COD)')}}</span>
                                        <input type="checkbox" id="payment" checked disabled>
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <b style="color: black;">{{$t('Shipping options')}}</b>
                                <div class="checkout__input__checkbox">
                                    <label for="payment">
                                        <span>{{$t('Free shipping')}}<b class="text-danger">
                                                {{$t('(Only in Morocco)')}}</b></span>
                                        <input type="checkbox" id="payment" checked disabled>
                                        <span class="checkmark"></span>
                                    </label>
                                </div>

                                <button type="submit" class="site-btn" :disabled="loading"
                                    v-if="cartItems.length > 0">{{ loading ? 'Processing...' : $t('Place Order') }} 
                                    <div v-if="loading" class="spinner"></div>
                                    </button>
                                <span type="submit" v-else class="site-btn-empty-cart text-danger" :disabled="true">
                                    {{$t('Your Cart is empty!')}} </span>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </section>
</template>
<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import Swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';
import {API_URL} from '../../services/apis'
export default {
    name: 'CheckoutPage',
    methods: {
		getOptionName(optionId) {
			//console.log(optionId)
			const product = this.cartItems.find(item => item.selectedOptions[optionId]);
			//console.log(product)
			const option = product.options.find(opt => opt.id == optionId);
			return option ? option.name : '';
		},
		getOptionValueName(optionValeurId) {
			const product = this.cartItems.find(item => {
				return Object.values(item.selectedOptions).some(opt => opt.optionValeurId === optionValeurId);
			});
			if (product) {
				for (const option of product.options) {
					const optionValeur = option.optionvaleurs.find(val => val.id === optionValeurId);
					if (optionValeur) {
						return optionValeur.name;
					}
				}
			}
			return '';
		}
	},
    setup() {
        const { t } = useI18n();

        const loading = ref(false);
        const isConnected = localStorage.getItem('isConnected');
        const store = useStore();

        const cartItems = computed(() => store.getters.cartItems);
        const cartTotal = computed(() => store.getters.cartTotal);
        const userData = JSON.parse(localStorage.getItem('userData'));
        const billingData = ref({
            name: '',
            surnom: '',
            ville: '',
            adresse: '',
            adrese_complete: '',
            code_postal: '',
            phone: '',
            email: '',
            is_registred: false,
            note: '',
            password: ''
        });
        const errorMessages = ref([]);
        const fileUserDataToForm = () =>{
            billingData.value.name = userData.detail.name;
            billingData.value.surnom = userData.detail.surnom;
            billingData.value.ville = userData.detail.ville;
            billingData.value.code_postal = userData.detail.code_postal;
            billingData.value.adresse = userData.detail.adresse;
            billingData.value.adrese_complete = userData.detail.adrese_complete;
            billingData.value.phone = userData.detail.phone;
            billingData.value.email = userData.user.email;
        };
        const validateForm = () => {
            errorMessages.value = [];
            const requiredFields = ['name', 'surnom', 'ville', 'adresse', 'code_postal', 'phone', 'email'];

            for (const field of requiredFields) {
                if (!billingData.value[field]) {
                    errorMessages.value.push(`The field ${field.replace('_', ' ')} is required  .`);
                }
            }

            if (billingData.value.is_registred && !billingData.value.password) {
                errorMessages.value.push('To create an account you should give the password field.');
            }

            return errorMessages.value.length === 0;
        };
        const placeOrderCheck = () => {
            if (!validateForm()) {
                return;
            }
            if (isConnected === '0' || !isConnected ) {
                placeOrderGuest();
            } else {
                placeOrder();
            }
        }
        const placeOrder = async () => {
            loading.value = true;
            const token = localStorage.getItem('token');
            //console.log(token)
            const orderData = {
                items: cartItems.value,
                total: cartTotal.value,
                details: billingData.value,
                coupon: discountCoupon.value
            };
            //console.log(orderData)
            try {
                const response = await fetch(API_URL+'/commande/add', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(orderData)
                });
                const data = await response.json();
                if (!response.ok) {
                    throw new Error('Failed to place the order');
                } else {
                    Swal.fire({
                        title: 'Thank you',
                        html: `Thanks for your order! Keep your track number: <strong style="color: red;">${data.data.track_number}</strong>`,
                        icon: 'success',
                        confirmButtonText: 'OK'
                    });
                    store.dispatch('clearCart');
                    couponValueData.value.coupon = '';
                    discountCoupon.value = 0;
                    errorMessagesCoupon.value = ref([]);
                }
                
            } catch (error) {
                console.error('Error placing order:', error.message);
            } finally {
                loading.value = false; // Set loading to false when request completes
            }
        };
        const placeOrderGuest = async () => {
            loading.value = true;
            //const token = localStorage.getItem('token');
            //console.log(token)
            const orderData = {
                items: cartItems.value,
                total: cartTotal.value,
                details: billingData.value
            };
            //console.log(orderData)
            try {
                const response = await fetch(API_URL+'/guest/commande/add', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(orderData)
                });
                //console.log('inside place order Guest')
                const data = await response.json();
                //console.log(data)
                if (!response.ok) {
                    throw new Error('Failed to place the order');
                } else {
                    Swal.fire({
                        title: 'Thank you',
                        html: `Thanks for your order! Keep your track number: <strong style="color: red;">${data.data.track_number}</strong>`,
                        icon: 'success',
                        confirmButtonText: 'OK'
                    });
                    store.dispatch('clearCart');
                }

                // Clear the cart after placing the order

            } catch (error) {
                console.error('Error placing order:', error.message);
            }finally{
                loading.value = false;
            }
        };
        //coupon
        const errorMessagesCoupon = ref([]);
        const couponValueData = ref({
            coupon: ''
        });
        const discountCoupon = ref(0)
        const errorText = ref(0)
        const validateFormCoupon = () => {
            errorMessagesCoupon.value = [];
            const requiredFields = ['coupon'];

            for (const field of requiredFields) {
                if (!couponValueData.value[field]) {
                    errorMessagesCoupon.value.push(`The field ${field.replace('_', ' ')} is required  .`);
                }
            }
            return errorMessagesCoupon.value.length === 0;
        };
        const couponCheck = () => {
            if (!validateFormCoupon()) {
                return;
            }
            addCoupon()
        }
        const addCoupon = async () => {
            const token = localStorage.getItem('token');
            //console.log(token)
            const couponData = {
                details: couponValueData.value
            };
            try {
                const response = await fetch(API_URL + '/commande/coupon/verify', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(couponData)
                });
                const data = await response.json();
                //console.log(data)
                if (data.success) {

                    errorText.value = 4
                    discountCoupon.value = data.data
                } else {
                    if (data.data.error === 'Coupon Invalid') {
                        errorText.value = 1;
                    } else if (data.data.error === 'Coupon aleardy used') {
                        errorText.value = 2;
                    } else {
                        errorText.value = 3;
                    }
                }

            } catch (error) {
                //console.log(error.data.error)
                if (error.data.error === 'Coupon Invalid') {
                    errorText.value = 1;
                } else if (error.data.error === 'Coupon aleardy used') {
                    errorText.value = 2;
                } else {
                    errorText.value = 3;
                }
                console.error('Error placing order:', error.message);
            }
        };
        return {
            cartItems,
            cartTotal,
            placeOrder,
            billingData,
            isConnected,
            placeOrderCheck,
            placeOrderGuest,
            errorMessages,
            loading,
            t,
            userData,
            fileUserDataToForm,
            errorMessagesCoupon,
            couponValueData,
            discountCoupon,
            errorText,
            couponCheck
        };
    },
};
</script>
<style scoped>
.signin-link {
    color: red;
    cursor: pointer;
}

.signin-link:hover {
    color: blue;
}
.site-btn-empty-cart{

	font-size: 14px;
	color: #ffffff;
	background: white;
	font-weight: 700;
	border: none;
	text-transform: uppercase;
	display: inline-block;
	padding: 14px 30px;
}
.site-btn-fill-data{
    font-size: 14px;
	color: red;
	background: white;
	font-weight: 700;
	border: 1px solid;
    cursor:pointer;
	text-transform: uppercase;
	display: inline-block;
	padding: 14px 30px;
}
.spinner {
    width: 18px;
    height: 18px;
    border: 2px solid #f3f3f3;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.site-btn[disabled] {
    background-color: grey;
    cursor: not-allowed;
}
</style>
