// src/store.js
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { API_URL } from './services/apis';
import axios from 'axios';

export default createStore({
  state: {
    cart: [],
    whishlist: [],
    adresse_store: null,
    phone_store: null,
    logo_store: null,
    logo_footer: null,
    nav_text: null,
    footer_text: null,
    email_store: null,
    instagram_store: null,
    facebook_store: null,
    whatsapp_store: null,
  },
  mutations: {
    //cart
    /*addToCart(state, { product, quantity, selectedData }) {
      const existingProductIndex = state.cart.findIndex(item => item.id === product.id && item.selectedData?.length === 0);
  
      if (existingProductIndex !== -1) {
        // Product without options exists in the cart, increase the quantity
        state.cart[existingProductIndex].quantity += quantity;
      } else {
        // Treat as a new product (either it's new or it has options)
        state.cart.push({ ...product, quantity, selectedData });
      }
    },*/
    addToCart(state, { product, quantity, selectedData }) {

      const existingProduct = state.cart.find(item => item.id === product.id);
      if (existingProduct) {
        existingProduct.quantity += quantity;
      } else {
        state.cart.push({ ...product, quantity, selectedData });
      }
    },
    updateCartQuantity(state, { productId, quantity }) {
      const product = state.cart.find(item => item.id === productId);
      if (product) {
        product.quantity = quantity;
      }
    },
    removeFromCart(state, productId) {
      state.cart = state.cart.filter(product => product.id !== productId);
    },
    clearCart(state) {
      state.cart = [];
    },
    //whishlist
    addToWhishlist(state, product) {
      const existingProduct = state.whishlist.find(item => item.id === product.id);
      if (!existingProduct) {
        console.log('addToWhishlist here');
        state.whishlist.push({ ...product });
      }
    },
    removeFromWhishlist(state, productId) {
      state.whishlist = state.whishlist.filter(product => product.id !== productId);
    },
    clearWhishlist(state) {
      state.whishlist = [];
    },
    //settings
    // Define mutations to update state properties
    updateAdresse(state, newAdresse) {
      state.adresse_store = newAdresse;
    },
    updatePhone(state, newPhone) {
      state.phone_store = newPhone;
    },
    updateLogo(state, newLogo) {
      state.logo_store = newLogo;
    },
    updateLogoFooter(state, newLogo) {
      state.logo_footer = newLogo;
    },
    updateNaveText(state, newText) {
      state.nav_text = newText;
    },
    updateFooterText(state, newtext) {
      state.footer_text = newtext;
    },
    updateEmailStore(state, newEmail) {
      state.email_store = newEmail;
    },
    updateInstagram(state, newInstagram) {
      state.instagram_store = newInstagram;
    },
    updateFacebook(state, newFacebook) {
      state.facebook_store = newFacebook;
    },
    updateWhatsapp(state, newWhatsapp) {
      state.whatsapp_store = newWhatsapp;
    }
  },
  actions: {
    //cart
    addToCart({ commit }, { product, quantity, selectedData }) {
      commit('addToCart', { product, quantity, selectedData });
    },
    updateCartQuantity({ commit }, { productId, quantity }) {
      commit('updateCartQuantity', { productId, quantity });
    },
    removeFromCart({ commit }, productId) {
      commit('removeFromCart', productId);
    },
    clearCart({ commit }) {
      commit('clearCart');
    },
    //whishlist
    addToWhishlist({ commit }, product) {
      commit('addToWhishlist', product);
    },
    removeFromWhishlist({ commit }, productId) {
      commit('removeFromWhishlist', productId);
    },
    clearWhishlist({ commit }) {
      commit('clearWhishlist');
    },
    //settings
    async fetchDataFromDatabase({ commit, state }) {
      try {
        // Faites une requête à votre API pour récupérer les données
        if (!state.adresse_store && !state.phone_store && !state.logo_store && !state.logo_footer && !state.nav_text && !state.footer_text && !state.email_store && !state.instagram_store && !state.facebook_store && !state.whatsapp_store) {
          const response = await axios.get(API_URL + '/ecom/settings');
          const data = response.data.data[0]; // Supposons que vos données soient dans la propriété "data" de la réponse
          console.log(data)
          // Mettre à jour l'état du magasin en appelant les mutations appropriées avec les données récupérées
          commit('updateAdresse', data.adresse);
          commit('updatePhone', data.phone);
          commit('updateLogo', data.logo);

          commit('updateAdresse', data.adresse_store);
          commit('updatePhone', data.phone_store);
          commit('updateLogo', data.logo_store);
          commit('updateLogoFooter', data.logo_footer);
          commit('updateNaveText', data.nav_text);
          commit('updateFooterText', data.footer_text);
          commit('updateEmailStore', data.email_store);
          commit('updateInstagram', data.instagram_store);
          commit('updateFacebook', data.facebook_store);
          commit('updateWhatsapp', data.whatsapp_store);
        }
        // Ajoutez des mutations supplémentaires pour les autres données si nécessaire
      } catch (error) {
        console.error('Erreur lors de la récupération des données depuis la base de données :', error);
      }
    },
  },
  getters: {
    //cart
    cartItems: (state) => state.cart,
    cartTotal: (state) => state.cart.reduce((total, product) => total + (product.prix * product.quantity), 0),
    cartCount: (state) => state.cart.reduce((count, product) => count + product.quantity, 0),
    //whishlist
    whishlistItems: (state) => state.whishlist,
    whishlistCount: (state) => state.whishlist.length,
    //settings
    adresse_store: state => state.adresse_store,
    phone_store: state => state.phone_store,
    logo_store: state => state.logo_store,
    logo_footer: state => state.logo_footer,
    nav_text: state => state.nav_text,
    footer_text: state => state.footer_text,
    email_store: state => state.email_store,
    instagram_store: state => state.instagram_store,
    facebook_store: state => state.facebook_store,
    whatsapp_store: state => state.whatsapp_store,

  },
  plugins: [createPersistedState()],
});
