import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '@/views/auth/LoginView.vue'
import RegisterView from '@/views/auth/RegisterView.vue'
import CartView from '@/views/CartView.vue'
import CheckoutView from '@/views/CheckoutView.vue'
import TrackView from '@/views/TrackView.vue'
import WhishlistView from '@/views/WhishlistView.vue'
import ShopView from '@/views/ShopView.vue'
import ProductView from '@/views/ProductView.vue'
import ContactView from '@/views/ContactView.vue'
import ProfileView from '@/views/ProfileView.vue'
import AboutView from '@/views/AboutView.vue'
import DeliveryView from '@/views/DeliveryView.vue'
import TermsView from '@/views/TermsView.vue'
import PrivacyView from '@/views/PrivacyView.vue'
import RequestPasswordReset from '@/views/auth/RequestPasswordReset.vue'
import ResetPassword from '@/views/auth/ResetPassword.vue'
import CategoriePage from '@/components/shop/CategoriePage.vue'
import BlogPage from '@/components/blog/BlogPage.vue'
import BlogDetailPage from '@/components/blog/BlogDetailPage.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/delivery-returns',
    name: 'delivery-returns',
    component: DeliveryView
  },
  {
    path: '/terms-condition',
    name: 'terms-conditions',
    component: TermsView
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/blog',
    name: 'blog',
    component: BlogPage
  },
  {
    path: '/post/:id',
    name: 'BlogPost',
    component: BlogDetailPage,
    props: true,
  },
  {
    path:'/shopping-cart',
    name:'cart',
    component:CartView

  },
  {
    path:'/checkout',
    name:'checkout',
    component:CheckoutView

  },
  {
    path:'/track-order',
    name:'trackOrder',
    component:TrackView

  },
  {
    path:'/whishlist',
    name:'whishlist',
    component:WhishlistView

  },
  {
    path:'/shop',
    name:'shop',
    component:ShopView

  },
  {
    path: '/product/:id',
    name: 'Product',
    component: ProductView,
    props: true // Enable route props to pass the id as a prop
  },
  {
    path:'/product/category/:slug',
    name:'product-by-category',
    component: CategoriePage,
    props:true
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: { requiresGuest: true }
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView,
    meta: { requiresGuest: true }
  },
  {
    path:'/request-password-reset',
    name:"request-password-reset",
    component:RequestPasswordReset,
    meta: { requiresGuest: true }
  },
  {
    path:'/reset-password',
    name:"reset-password",
    component:ResetPassword,
    meta: { requiresGuest: true }
  },
  {
    path:'/profile',
    name:'profile',
    component:ProfileView,
    meta : { requiresAuth:true }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Navigation guard to check for authenticated users
router.beforeEach((to, from, next) => {

  const isConnected = localStorage.getItem('isConnected') === '1';

  if (to.matched.some(record => record.meta.requiresGuest) && isConnected) {
    next({ name: 'home' }); // Redirect to home if already logged in
  } else if (to.matched.some(record => record.meta.requiresAuth) && !isConnected) {
    next({ name: 'login' }); // Redirect to login if not logged in and route requires auth
  } else {
    next(); // Proceed to the route
  }
})

export default router
