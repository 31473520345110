<template>
  <!-- Hero Section Begin -->
  <section class="hero">
    <div id="heroCarousel" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item" :class="{ active: index === 0 }" v-for="(slide, index) in slides" :key="index">
          <img :src="slide.image" class="d-block w-100" :alt="slide.title">
          <div class="carousel-caption d-none d-md-block text-start ">
            <div class="caption-bg">
              <h6 style="color:white;">{{ t(slide.collection) }}</h6>
              <h2 style="color:white;">{{ t(slide.title) }}</h2>
              <p style="color:white;">{{ t(slide.description) }}</p>
              <a href="/shop" class="btn bg-white btn-shop-slider">{{ $t('Shop now') }}<span class="arrow_right"></span></a>
              
            </div>
          </div>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#heroCarousel" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true" style="background-color:black"></span>
        <span class="visually-hidden">{{ $t('Previous') }}</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#heroCarousel" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true" style="background-color:black"></span>
        <span class="visually-hidden">{{ $t('Next') }}</span>
      </button>
    </div>
  </section>
  <!-- Hero Section End -->
</template>

<script>
// @ is an alias to /src
import heroImage1 from '../../assets/img/hero/women-2.jpg';
import heroImage2 from '../../assets/img/hero/women-3.jpg';
import heroImage3 from '../../assets/img/hero/men-2.jpg'
import heroImage4 from '../../assets/img/hero/men-3.jpg'
import heroImage5 from '../../assets/img/hero/men-4.jpg'
import { useI18n } from 'vue-i18n';
export default {
  name: 'SliderComponent',
  setup() {
    const { t } = useI18n();

    return {
      t
    };
  },
  data() {
    return {
      slides: [
        {
          "image": heroImage1,
          "collection": "Lynx Polarized Sunglasses",
          "title": "Polarized Perfection",
          "description": "Discover LYNX's Polarized Sunglasses Collection, offering POLARISE technology for superior glare reduction. Look Different with lightweight and stylish designs featuring ANTI UV 400 and CAT 3 certifications."
        },
        {
          "image": heroImage2,
          "collection": "Lynx Lightweight Sunglasses",
          "title": "Featherlight Fashion",
          "description": "Experience the ultimate in comfort with LYNX's Lightweight Sunglasses Collection. Our LEGERETE frames provide a barely-there feel, while ANTI UV 400 protection ensures your eyes stay safe. Look Different and stay protected."
        },
        {
          "image": heroImage3,
          "collection": "Lynx UV Protection Sunglasses",
          "title": "Maximum UV Protection",
          "description": "Shield your eyes with LYNX's UV Protection Sunglasses. Featuring ANTI UV 400 certification, these sunglasses block harmful rays while offering stylish designs. Look Different and stay safe in the sun."
        },
        {
          "image": heroImage4,
          "collection": "Lynx Category 3 Sunglasses",
          "title": "High Performance Eyewear",
          "description": "Explore LYNX's Category 3 Sunglasses for high sun glare protection. Our CAT 3 lenses ensure optimal visibility and comfort. Look Different with cutting-edge eyewear designed for active lifestyles."
        },
        {
          "image": heroImage5,
          "collection": "Lynx All-Rounder Sunglasses",
          "title": "Versatile and Stylish",
          "description": "Choose LYNX's All-Rounder Sunglasses for a blend of all our best features: POLARISE, LEGERETE, ANTI UV 400, and CAT 3. Look Different with versatile sunglasses that meet all your needs."
        },
      ],
    };
  },
}
</script>
<style scoped>
.hero {
  position: relative;
}

.carousel-caption {
  bottom: 20%;
}
.caption-bg {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  padding: 10px;
  border-radius: 5px;
}
.hero__social {
  margin-top: 15px;
}

.hero__social a {
  color: #fff;
  margin-right: 10px;
  font-size: 18px;
}

.btn-primary {
  background-color: black;
  border-color: black;
}
.btn-shop-slider :hover{
  color:black;
}
</style>