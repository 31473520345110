
import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import router from './router'
import './assets/style.css'
import '@fortawesome/fontawesome-free/css/all.min.css';
import store from './store';
import i18n from './i18n';
import { createHead } from '@vueuse/head';
import CKEditor from '@ckeditor/ckeditor5-vue';
import VueLazyload from 'vue-lazyload';
const head = createHead();

createApp(App).use(router).use(store).use(i18n).use(head).use( CKEditor ).use(VueLazyload, {
    preLoad: 1.3,
    error: 'path/to/error.png', // Optional error placeholder
    loading: 'path/to/loading.gif', // Optional loading placeholder
    attempt: 1
  }).mount('#app');
