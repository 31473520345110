<template>
    <DeliveryPage />
  </template>
  <script>
import DeliveryPage from '@/components/pages/DeliveryPage.vue'
  export default {
  name: 'DeliveryView',
  components:{
    DeliveryPage
  }
  }
  </script>