<template>
    <footer class="footer mt-5">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="footer__about">
                        <div class="footer__logo">
                            <a href="#"><img src="../../assets/img/lynx/lynx-words.png" alt=""></a>
                        </div>
                        <p>{{$t("LYNX's Innovatively designed glasses, combining style, comfort, and Moroccan craftsmanship.")}}
                        </p>
                        <p class="text-white">{{$t('Contact')}} : {{ email_store }}</p >
                        <span class="text-white">{{$t('Follow Us')}}: </span>
                        <a href="#" title="Facebook Page"><i class="fa-brands fa-facebook text-primary me-1"></i></a>
                        <a href="#" title="Instagram Page"><i class="fa-brands fa-square-instagram text-danger me-1"></i></a>
                    </div>
                </div>
                <div class="col-lg-2 offset-lg-1 col-md-3 col-sm-6">
                    <div class="footer__widget">
                        <h6>{{$t('QUICK LINKS')}}</h6>
                        <ul>
                            <li><a href="/about">{{$t('Our Story')}}</a></li>
                            <li><a href="/terms-condition">{{$t('Terms and Conditions')}}</a></li>
                            <li><a href="/delivery-returns">{{$t('Delivery and returns')}}</a></li>
                            <li><a href="/privacy-policy">{{$t('Privacy and Policy')}}</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-6">
                    <div class="footer__widget">
                        <h6>{{$t('Shopping')}}</h6>
                        <ul>
                            <li><a href="/shop">{{$t('Our Store')}}</a></li>
                            <li><a href="/contact">{{$t('Contact')}}</a></li>
                            <li><a href="/shopping-cart">{{$t('Shopping Cart')}}</a></li>
                            <li><a href="/checkout">{{$t('Checkout')}}</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 offset-lg-1 col-md-6 col-sm-6">
                    <div class="footer__widget">
                        <h6>{{$t('NewsLetter')}}</h6>
                        <div class="footer__newslatter">
                            <p>{{ $t('Be the first to know about new arrivals, sales & promos!')}}</p>
                            <form @submit.prevent="addEmailToNewsLetter">
                                <input type="text" :placeholder="$t('Email')" v-model="newsLetterValue.email">
                                <button type="submit" title="Be our friend!"><i class="fa fa-envelope"
                                        aria-hidden="true"></i></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 text-center">
                    <div class="footer__copyright__text">
                        <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
                        <p>Copyright © 2024 
                            All rights reserved to <b class="text-warning">LYNX</b> | Made with ❤️ by <a
                                href="https://qsd.ma" target="_blank">QSD</a>
                        </p>
                        <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
  
  <script>
  import { ref } from 'vue';
  import Swal from 'sweetalert2';
  import { useI18n } from 'vue-i18n';
  import { API_URL } from '@/services/apis';
  import { mapActions,mapGetters } from 'vuex';

  export default {
    name: 'FooterView',
    methods: {
		...mapActions(['fetchDataFromDatabase']), // Mappez l'action fetchDataFromDatabase à une méthode dans votre composant
		async fetchData() {
			// Appeler l'action pour récupérer les données
			await this.fetchDataFromDatabase();
			// Les données sont maintenant disponibles dans votre magasin Vuex et peuvent être utilisées via des getters dans votre composant
		}
	},
	mounted() {
		this.fetchData();
	},
	computed: {
		...mapGetters([
			'adresse_store',
			'phone_store',
			'logo_store',
            'logo_footer',
			'nav_text',
			'footer_text',
			'email_store',
			'instagram_store',
			'facebook_store',
			'whatsapp_store',
			// Ajoutez d'autres getters pour les données supplémentaires si nécessaire
		]),
	},
    setup() {
        const { t } = useI18n();
        const newsLetterValue = ref({
            email: ''
        });
        const addEmailToNewsLetter = async () => {
            const newsData = {
                details: newsLetterValue.value
            };
            try {
                const response = await fetch(API_URL+'/newsletter/rejoindre', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(newsData)
                });
                if (!response.ok) {
                    throw new Error('Failed to place the order');
                } else {
                    Swal.fire({
                        title: 'Thank you',
                        html: `Your Email Has been stored ! ^___^`,
                        icon: 'success',
                        confirmButtonText: 'OK'
                    });
                }
                
            } catch (error) {
                console.error('Error placing order:', error.message);
            }
        };
        return {
            addEmailToNewsLetter,newsLetterValue,t
        };
    },
  }
  </script>
  
  <style scoped>
    .footer__widget a{
        text-decoration:none;
    }
  </style>  