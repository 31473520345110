<template>
    <section class="about spad">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="about__pic">
                        <img src="../../assets/img/hero/women-1.jpg" alt="">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="about__item">
                        <h4 class="text-center">Privacy Policy</h4>
                        <h2>Introduction</h2>
                        <p>Welcome to LYNX's Privacy Policy. Your privacy is critically important to us. LYNX  is
                            committed to protecting the personal information of our customers and users. This policy
                            outlines our practices regarding the collection, use, and protection of your personal data.
                        </p>

                        <h2>Information We Collect</h2>
                        <p>We collect various types of information in connection with the services we provide,
                            including:</p>
                        <ul>
                            <li><strong>Personal Identification Information:</strong> Name, email address, phone number,
                                etc.</li>
                            <li><strong>Payment Information:</strong> Credit card details and other financial
                                information for processing transactions.</li>
                            <li><strong>Technical Data:</strong> IP address, browser type, and operating system,
                                collected to enhance your browsing experience.</li>
                            <li><strong>Usage Data:</strong> Information on how you use our website, products, and
                                services.</li>
                        </ul>

                        <h2>How We Use Your Information</h2>
                        <p>LYNX   uses the collected data for various purposes:</p>
                        <ul>
                            <li>To process and manage your orders.</li>
                            <li>To provide customer support.</li>
                            <li>To improve our website, products, and services.</li>
                            <li>To send promotional emails and offers, with your consent.</li>
                            <li>To comply with legal obligations.</li>
                        </ul>

                        <h2>How We Protect Your Information</h2>
                        <p>We implement a variety of security measures to maintain the safety of your personal
                            information. This includes the use of secure servers, encryption, and other technical and
                            organizational measures to protect against unauthorized access, alteration, disclosure, or
                            destruction of your personal data.</p>

                        <h2>Sharing Your Information</h2>
                        <p>We do not sell, trade, or otherwise transfer to outside parties your personal information,
                            except as described below:</p>
                        <ul>
                            <li>With third-party service providers who assist us in operating our website, conducting
                                our business, or servicing you, so long as those parties agree to keep this information
                                confidential.</li>
                            <li>To comply with the law, enforce our site policies, or protect ours or others' rights,
                                property, or safety.</li>
                            <li>With your consent.</li>
                        </ul>

                        <h2>Cookies</h2>
                        <p>Our website uses cookies to enhance your experience. Cookies are small files that a site or
                            its service provider transfers to your computer’s hard drive through your web browser (if
                            you allow) that enables the site’s or service provider’s systems to recognize your browser
                            and capture and remember certain information.</p>
                        <p>You can choose to have your computer warn you each time a cookie is being sent, or you can
                            choose to turn off all cookies. Each browser is a little different, so look at your
                            browser’s Help Menu to learn the correct way to modify your cookies.</p>

                        <h2>Your Rights</h2>
                        <p>You have the right to access, correct, or delete your personal data. You also have the right
                            to object to or restrict certain types of processing of your personal data. To exercise
                            these rights, please contact us at the information provided below.</p>

                        <h2>Changes to This Privacy Policy</h2>
                        <p>LYNX   may update this Privacy Policy from time to time. We will notify you of any changes
                            by posting the new Privacy Policy on this page. You are advised to review this Privacy
                            Policy periodically for any changes. Changes to this Privacy Policy are effective when they
                            are posted on this page.</p>

                        <h2>Contact Us</h2>
                        <p>If you have any questions about this Privacy Policy, please contact us:</p>
                        <ul>
                            <li><strong>By email:</strong> support@lynxlook.com</li>
                            <li><strong>By phone:</strong> +212 655 69 7226</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'PrivacyPage',
}
</script>