<template>
  <div class="container mt-5">
    <p><span class="h4">{{$t('Welcome to LYNX,')}}</span>{{ $t('about_home_p1') }}</p>
  </div>
  <div class="row justify-content-center mt-5">
      <div class="col-xl-7 col-lg-8 col-md-10">
        <div class="section-tittle mb-60 text-center"  data-wow-duration="2s"
          data-wow-delay=".2s" >
          <h2>{{$t('OUR')}}<br />{{$t('CATALOGUE')}}</h2>
        </div>
      </div>
    </div>
  <div class="container mt-5">
    <div class="options">
      <div v-for="(option, index) in options" :key="index" :class="{ 'option': true, 'active': index === activeIndex }"
        :style="{ '--optionBackground': option.background }" @click="setActive(index)">
        <div class="shadow"></div>
        <div class="label">
          <div class="icon">
            <i :class="option.icon"></i>
          </div>
          <div class="info">
            <div class="main"><a :href="'/product/category/'+option.slug" class="site-btn-category">{{ option.main }} </a></div>
            <div class="sub">{{ option.sub }} </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { useI18n } from 'vue-i18n';
export default {
  name: 'CategorieComponent',
  setup() {
      const { t } = useI18n();

      return {
        t
      };
    },
  data() {
    return {
      activeIndex: 0,
      options: [
        {
          background: `url('/assets/img/banner/homme2.jpg')`,
          icon: 'fas fa-walking',
          main: 'MEN',
          slug:'homme',
          sub: "Sophisticated eyewear for urban gentlemen with style."
        },
        {
          background: `url('/assets/img/banner/femme2.jpg')`,
          icon: 'fas fa-walking',
          main: 'WOMEN',
          slug: 'femme',
          sub: "Glamorous sunglasses for the woman who shines bright."
        },
        {
          background: `url('/assets/img/banner/unisexe2.jpg')`,
          icon: 'fas fa-walking',
          main: 'UNISEXE',
          slug: 'unisexe',
          sub: "Sunglasses that blur boundaries, unite diverse styles effortlessly."
        },
        // Repeat for other options
      ]
    };
  },
  methods: {
    setActive(index) {
      this.activeIndex = index;
    }
  }
};
</script>

<style scoped>
@import url(https://pro.fontawesome.com/releases/v5.11.2/css/all.css);

:root {
  --user-button-circle: rgba(0, 0, 0, 0.025);
  --user-button-cardborder: rgba(255, 255, 255, 0.25);
  --user-button-text: #323133;
  --user-button-shadow: rgba(0, 0, 0, 0.1);
}

body #user-button {
  z-index: 1000;
  bottom: 1rem !important;
  right: 1rem !important;
  color: var(--user-button-text);
  transition: 1s 0s ease-out;
  -webkit-animation: slide 3s ease-out forwards;
  animation: slide 3s ease-out forwards;
}

@-webkit-keyframes slide {

  0%,
  50% {
    opacity: 0;
    display: block !important;
  }

  100% {
    opacity: 1;
    display: block !important;
  }
}

@keyframes slide {

  0%,
  50% {
    opacity: 0;
    display: block !important;
  }

  100% {
    opacity: 1;
    display: block !important;
  }
}

body #user-button .u-card {
  border-radius: 100%;
  box-shadow: 0 0 1rem -0.25rem var(--user-button-shadow), inset 0 0 1rem -0.75rem var(--user-button-shadow);
}

body #user-button .u-main {
  cursor: pointer;
  --user-button-background: var(--user-button-main, #EC87C0);
}

body #user-button .u-main img {
  height: 100%;
  width: 100%;
}

body #user-button .u-main iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 4rem;
  height: 4rem;
  opacity: 1;
  transition: 0s 4s;
}

body #user-button .u-icons {
  position: relative;
  z-index: 950;
  transform: translate(-50%, -50%);
  background: var(--user-button-circle);
  box-shadow: 0 0 0 0.125rem var(--user-button-cardborder);
  border-radius: 100%;
  transition: 0.25s;
  opacity: 1 !important;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  /*&:before {
     z-index:-1;
     position:absolute;
     top:0; right:0; bottom:0; left:0;
     content:'';

     backdrop-filter: blur(10px);
  }*/
}

body #user-button .u-icons a {
  color: inherit;
  display: grid;
  place-items: center;
  width: 30px;
  height: 30px;
  text-decoration: none;
}

body #user-button .u-icons a div {
  padding: 0.5rem;
  transition: 0s;
}

body #user-button .u-icons a[href="https://twitter.com/zed_dash"] {
  position: relative;
}

body #user-button .u-icons a[href="https://twitter.com/zed_dash"]:before {
  content: "Middle Click";
  position: absolute;
  top: -1.5rem;
  left: 50%;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  white-space: pre;
  transform: translateX(-50%);
  opacity: 0;
  pointer-events: none;
  transition: 0.25s ease-in;
  background: #fffc;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
}

body #user-button .u-icons a[href="https://twitter.com/zed_dash"].show:before {
  opacity: 1;
  transition: 0.25s ease-out;
}

body #user-button .u-icons a[href="https://twitter.com/zed_dash"] div {
  color: #1da1f2;
}

body #user-button .u-icons a[href="https://codepen.io/z-"] div {
  background: black;
  color: white;
}

body #user-button .u-icons a.u-random div {
  position: relative;
  top: -1px;
  -webkit-animation: diespin 2s linear infinite;
  animation: diespin 2s linear infinite;
}

@-webkit-keyframes diespin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes diespin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

body #user-button .u-icons a.u-random:not(:hover) div {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

body #user-button .u-icons>* {
  position: absolute;
  width: 30px;
  height: 30px;
  background: var(--singlecolour);
  border-radius: 100%;
  cursor: pointer;
  transform: translate(-50%, -50%);
  transition: 0.25s -0.05s;
}

body #user-button .u-icons>*:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

body #user-button .u-icons>*:hover,
body #user-button .u-icons>*:focus-within {
  background: var(--hcolour);
}

body #user-button .u-icons>*:first-child:nth-last-child(1):nth-child(1),
body #user-button .u-icons>*:first-child:nth-last-child(1)~*:nth-child(1) {
  left: 25%;
  top: 25%;
}

body #user-button .u-icons>*:first-child:nth-last-child(2):nth-child(1),
body #user-button .u-icons>*:first-child:nth-last-child(2)~*:nth-child(1) {
  left: 37.5%;
  top: 18.75%;
}

body #user-button .u-icons>*:first-child:nth-last-child(2):nth-child(2),
body #user-button .u-icons>*:first-child:nth-last-child(2)~*:nth-child(2) {
  left: 18.75%;
  top: 37.5%;
}

body #user-button .u-icons>*:first-child:nth-last-child(3):nth-child(1),
body #user-button .u-icons>*:first-child:nth-last-child(3)~*:nth-child(1) {
  left: 50%;
  top: 15.625%;
}

body #user-button .u-icons>*:first-child:nth-last-child(3):nth-child(2),
body #user-button .u-icons>*:first-child:nth-last-child(3)~*:nth-child(2) {
  left: 25%;
  top: 25%;
}

body #user-button .u-icons>*:first-child:nth-last-child(3):nth-child(3),
body #user-button .u-icons>*:first-child:nth-last-child(3)~*:nth-child(3) {
  left: 15.625%;
  top: 50%;
}

body #user-button .u-icons>*:first-child:nth-last-child(4):nth-child(1),
body #user-button .u-icons>*:first-child:nth-last-child(4)~*:nth-child(1) {
  left: 62.5%;
  top: 18.75%;
}

body #user-button .u-icons>*:first-child:nth-last-child(4):nth-child(2),
body #user-button .u-icons>*:first-child:nth-last-child(4)~*:nth-child(2) {
  left: 37.5%;
  top: 18.75%;
}

body #user-button .u-icons>*:first-child:nth-last-child(4):nth-child(3),
body #user-button .u-icons>*:first-child:nth-last-child(4)~*:nth-child(3) {
  left: 18.75%;
  top: 37.5%;
}

body #user-button .u-icons>*:first-child:nth-last-child(4):nth-child(4),
body #user-button .u-icons>*:first-child:nth-last-child(4)~*:nth-child(4) {
  left: 18.75%;
  top: 62.5%;
}

body #user-button:hover .u-icons,
body #user-button:focus-within .u-icons {
  width: 300% !important;
  height: 300% !important;
}

body {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 100vh;
  font-family: "Roboto", sans-serif;
  transition: 0.25s;
}

body.dark {
  background: #232223;
  color: white;
}

@media (prefers-color-scheme: dark) {
  body:not(.light) {
    background: #232223;
    color: white;
  }
}

body .credit {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: inherit;
}

body .options {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  overflow: hidden;
  min-width: 600px;
  max-width: 900px;
  width: calc(100% - 100px);
  height: 400px;
}

@media screen and (max-width: 718px) {
  body .options {
    min-width: 520px;
  }

  body .options .option:nth-child(5) {
    display: none;
  }
}

@media screen and (max-width: 638px) {
  body .options {
    min-width: 440px;
  }

  body .options .option:nth-child(4) {
    display: none;
  }
}

@media screen and (max-width: 558px) {
  body .options {
    min-width: 360px;
  }

  body .options .option:nth-child(3) {
    display: none;
  }
}

@media screen and (max-width: 478px) {
  body .options {
    min-width: 280px;
  }

  body .options .option:nth-child(2) {
    display: none;
  }
}

body .options .option {
  position: relative;
  overflow: hidden;
  min-width: 60px;
  margin: 10px;
  background: var(--optionBackground, var(--defaultBackground, #E6E9ED));
  background-size: auto 120%;
  background-position: center;
  cursor: pointer;
  transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
}

body .options .option:nth-child(1) {
  --defaultBackground: #ED5565;
}

body .options .option:nth-child(2) {
  --defaultBackground: #FC6E51;
}

body .options .option:nth-child(3) {
  --defaultBackground: #FFCE54;
}

body .options .option:nth-child(4) {
  --defaultBackground: #2ECC71;
}

body .options .option:nth-child(5) {
  --defaultBackground: #5D9CEC;
}

body .options .option:nth-child(6) {
  --defaultBackground: #AC92EC;
}

body .options .option.active {
  flex-grow: 10000;
  transform: scale(1);
  max-width: 600px;
  margin: 0px;
  border-radius: 40px;
  background-size: auto 100%;
  /*&:active {
     transform:scale(0.9);
  }*/
}

body .options .option.active .shadow {
  box-shadow: inset 0 -120px 120px -120px black, inset 0 -120px 120px -100px black;
}

body .options .option.active .label {
  bottom: 20px;
  left: 20px;
}

body .options .option.active .label .info>div {
  left: 0px;
  opacity: 1;
}

body .options .option:not(.active) {
  flex-grow: 1;
  border-radius: 30px;
}

body .options .option:not(.active) .shadow {
  bottom: -40px;
  box-shadow: inset 0 -120px 0px -120px black, inset 0 -120px 0px -100px black;
}

body .options .option:not(.active) .label {
  bottom: 10px;
  left: 10px;
}

body .options .option:not(.active) .label .info>div {
  left: 20px;
  opacity: 0;
}

body .options .option .shadow {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 120px;
  transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
}

body .options .option .label {
  display: flex;
  position: absolute;
  right: 0px;
  height: 40px;
  transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
}

body .options .option .label .icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: white;
  color: var(--defaultBackground);
}

body .options .option .label .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  color: white;
  white-space: pre;
}

body .options .option .label .info>div {
  position: relative;
  transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95), opacity 0.5s ease-out;
}

body .options .option .label .info .main {
  font-weight: bold;
  font-size: 1.2rem;
}

body .options .option .label .info .sub {
  transition-delay: 0.1s;
}
.site-btn-category{
  font-size: 14px;
	color: #ffffff;
	font-weight: 700;
	border: none;
	text-transform: uppercase;
	display: inline-block;
	padding: 14px 10px;
}
</style>