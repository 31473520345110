// src/composables/useInactivityLogout.js
import { ref, onMounted, onUnmounted } from 'vue';
import { API_URL } from "./apis";

export function useInactivityLogout(timeout = 7200000) { // Default to 2 hours
    const lastActivity = ref(Date.now());

    const resetTimer = () => {
        lastActivity.value = Date.now();
    };

    const checkInactivity = () => {
        if (Date.now() - lastActivity.value >= timeout) {
            logoutUser();
        }
    };

    const logoutUser = async () => {
        console.log('hello')
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(API_URL + '/logout', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            console.log(response)
            localStorage.setItem('isConnected', '0');
            localStorage.setItem('userData', 'null');
            localStorage.setItem('token', 'null');
            window.location.reload();
        } catch (error) {
            console.error('Error placing order:', error.message);
        }
    };

    const setListeners = () => {
        document.addEventListener('mousemove', resetTimer);
        document.addEventListener('keypress', resetTimer);
        document.addEventListener('mousedown', resetTimer); // For detecting mouse clicks
        document.addEventListener('touchstart', resetTimer); // For detecting touch events
        document.addEventListener('scroll', resetTimer); // For detecting scroll events
    };

    const clearListeners = () => {
        document.removeEventListener('mousemove', resetTimer);
        document.removeEventListener('keypress', resetTimer);
        document.removeEventListener('mousedown', resetTimer);
        document.removeEventListener('touchstart', resetTimer);
        document.removeEventListener('scroll', resetTimer);
    };

    onMounted(() => {
        setListeners();
        setInterval(checkInactivity, 1000); // Check every 10 seconds
    });

    onUnmounted(() => {
        clearListeners();
    });
}
