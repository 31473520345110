<template>
    <!-- Map Begin -->
    <div class="map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3309.7208069996313!2d-6.871694324488088!3d33.94830827319629!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda71325052627f9%3A0x42436605730a80fc!2sAswak%20Assalam%20-%20Hay%20Riad!5e0!3m2!1sfr!2sma!4v1718490222067!5m2!1sfr!2sma" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
    <!-- Map End -->

    <!-- Contact Section Begin -->
    <section class="contact spad">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <div class="contact__text">
                        <div class="section-title">
                            <span>Information</span>
                            <h2>{{ $t('Contact Us') }}</h2>
                            <p><strong>{{ $t('YOUR FEEDBACK MATTERS!') }}</strong><br />{{ $t('keep_in_touch') }}</p>
                        </div>
                        <ul>
                            <li>
                                <h4>Morocco</h4>
                                <p><i class="fa-solid fa-envelope" title="Email"></i> contact@lynxlook.com <br />
                                    <i class="fa-solid fa-phone" title="Phone"></i> (+212) 655 69 7226<br />
                                    <i class="fa-brands fa-whatsapp" title="Whatsapp"></i> (+212) 655 69 7226
                                </p>
                            </li>
                            <li>
                                <h4>{{ $t('Our Stands') }}</h4>
                                <p><i class="fa-solid fa-location-dot" title="location  "></i> Aswak Assalam - Hay Riad,RABAT,MOROCCO</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="contact__form">
                        <form @submit.prevent="contactSubmit">
                            <div v-if="errorMessages.length" class="alert alert-danger" role="alert">
                                <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img"
                                    aria-label="Warning:">
                                    <use xlink:href="#exclamation-triangle-fill" />
                                </svg>
                                <ul>
                                    <span v-for="(message, index) in errorMessages" :key="index">
                                        <i class="bi bi-exclamation-circle-fill"></i> {{ message }}<br>
                                    </span>
                                </ul>
                                <button type="button" class="btn-close" data-bs-dismiss="alert"
                                    aria-label="Close"></button>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <input type="text" :placeholder="$t('Name')" v-model="contactData.name" required>
                                </div>
                                <div class="col-lg-6">
                                    <input type="email" :placeholder="$t('Email')" v-model="contactData.email" required>
                                </div>
                                <div class="col-lg-6">
                                    <input type="text" :placeholder="$t('Subject')" v-model="contactData.subject" required>
                                </div>
                                <div class="col-lg-6">
                                    <input type="text" :placeholder="$t('Phone')" v-model="contactData.phone" required>
                                </div>
                                <div class="col-lg-12">
                                    <textarea :placeholder="$t('Message')" v-model="contactData.message" required></textarea>
                                    <button type="submit" class="site-btn">{{ $t('Send Message') }}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Contact Section End -->
</template>
<script>
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import Swal from 'sweetalert2';
import {API_URL} from '../../services/apis'
import { useHead } from '@vueuse/head';
export default {
    name: 'ContactPage',
    setup() {
        useHead({
			title: 'LYNX | Acheter Lunettes de Soleil au Maroc - Votre Boutique en Ligne - Contact us LYNX',
			meta: [
				{ name: 'description', content: 'LYNX Découvrez notre collection de lunettes de soleil tendance au Maroc. Protection UV, matériaux de qualité, et livraison rapide. Achetez maintenant!' },
				{ name: 'keywords', content: 'LYNX,lynx,look different,acheter lunettes de soleil Maroc, lunettes de soleil tendance, protection UV, lunettes en bois, lunettes de vue Maroc' }
			]
		});
        const { t } = useI18n();
        const loading = ref(false);
        const contactData = ref({
            name: '',
            phone: '',
            email: '',
            subject:'',
            message:''
        });
        const errorMessages = ref([]);

        const validateForm = () => {
            errorMessages.value = [];
            const requiredFields = ['name', 'email', 'subject', 'message', 'phone'];

            for (const field of requiredFields) {
                if (!contactData.value[field]) {
                    errorMessages.value.push(`The field ${field.replace('_', ' ')} is required  .`);
                }
            }

            return errorMessages.value.length === 0;
        };
        const contactSubmit = async () => {
            if (!validateForm()) {
                return;
            }
            loading.value = true;
            const orderData = {
                details: contactData.value
            };
            //console.log(orderData)
            try {
                const response = await fetch(API_URL+'/contact/add', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(orderData)
                });
                if (!response.ok) {
                    throw new Error('Failed to place the order');
                } else {
                    Swal.fire({
                        title: 'Thank you',
                        html: `Your message sent!`,
                        icon: 'success',
                        confirmButtonText: 'OK'
                    });
                }
                
            } catch (error) {
                console.error('Error placing order:', error.message);
            } finally {
                loading.value = false; // Set loading to false when request completes
            }
        };
        return {
            t,contactData,contactSubmit,validateForm,errorMessages
        };
    },
};
</script>
