export const sleep = (ms = 0) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }
  
  /** Validation */
  export const validators = {
    email: (v) => {
      const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return pattern.test(v) ? true : 'Please enter a valid email address'
    },
    required: (v) => !!v || 'This field is required',
  }
  
  