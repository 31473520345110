<template>
    <section class="features-wrap py-5">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-7 col-lg-8 col-md-10">
                    <div class="section-tittle mb-60 text-center wow fadeInUp"
                        data-wow-duration="2s" data-wow-delay=".2s" >
                        <h2>{{ $t('Why LYNX?') }}</h2>
                    </div>
                </div>
            </div>
            <h2 class="text-center mb-5"></h2>
            <div class="row gy-4">
                <div class="col-md-4">
                    <div class="single-feature text-center p-4 shadow rounded">
                        <div class="feature-icon"><i class="fas fa-money-bill-wave"></i></div>
                        <h5>{{ $t('CASH ON DELIVERY') }}</h5>
                        <p>{{ $t('Pay when you receive your order') }}</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="single-feature text-center p-4 shadow rounded">
                        <div class="feature-icon"><i class="fas fa-truck"></i></div>
                        <h5>{{ $t('FREE SHIPPING') }}</h5>
                        <p>{{ $t('For all orders') }}</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="single-feature text-center p-4 shadow rounded">
                        <div class="feature-icon"><i class="fas fa-clock"></i></div>
                        <h5>{{ $t('FAST DELIVERY') }}</h5>
                        <p>{{ $t('Rabat, Sale, Temara: 1 Day') }}<br>{{ $t('Other cities: 2-3 Days') }}</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="single-feature text-center p-4 shadow rounded">
                        <div class="feature-icon"><i class="fas fa-sync-alt"></i></div>
                        <h5>{{ $t('3-DAY REFUND') }}</h5>
                        <p>{{ $t('Return within 3 days after delivery') }}</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="single-feature text-center p-4 shadow rounded">
                        <div class="feature-icon"><i class="fas fa-headphones"></i></div>
                        <h5>{{ $t('24//7 SUPPORT') }}</h5>
                        <p>{{ $t('Support every time') }}</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="single-feature text-center p-4 shadow rounded">
                        <div class="feature-icon"><i class="fas fa-book"></i></div>
                        <h5>{{ $t('EDUCATIONAL RESOURCES') }}</h5>
                        <p>{{ $t('Learn about eye health and care') }}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="banner-wrap one-column-banner py-4">
        <div class="container">
            <a href="/shop" target="_blank" class="banner">
                <img src="../../assets/img/hero/women-3.jpg" alt="Banner" class="img-fluid rounded">
                <div class="banner-text">
                    <span>{{$t("Eyes are the windows to the soul, let us protect yours with style. Shop the finest collection of sunglasses, optics, and anti-UV glasses at LYNX. See the world in a new light!")}}</span>
                </div>
            </a>
        </div>
    </section>

</template>
<script>
import { useI18n } from 'vue-i18n';
export default {
    name: 'InfoComponent',
    setup() {
        const { t } = useI18n();

        return {
            t
        };
    },
}
</script>

<style scoped>
.single-feature {
    background-color: black;
    padding: 30px;
    border-radius: 10px;
    transition: all 0.3s ease;
}

.single-feature:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.feature-icon {
    font-size: 36px;
    margin-bottom: 20px;
    color: white;
}

h5 {
    font-size: 18px;
    margin-bottom: 10px;
    color: white
}

p {
    font-size: 14px;
    color: white;
}

.banner {
    position: relative;
    display: block;
}

.banner img {
    width: 100%;
    height: auto;
}

.banner-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.6);
    padding: 10px 20px;
    border-radius: 5px;
    color: white !important;
    text-align: center;
}
</style>