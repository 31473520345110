<template>
    <section class="about spad">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="about__pic">
                        <img src="../../assets/img/hero/men-2.jpg" alt="">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="about__item">
                        <h4 class="text-center">Terms and Conditions</h4>
                        <div class="alert alert-warning">
                            <h2>Warning</h2>
                            <p>You have just logged onto the lynxlook.com website operated by LYNX . Please read
                                the following provisions carefully, as they establish the general terms and conditions
                                for the products and services available in the electronic catalog (or Stores&Stands) of LYNX
                                .</p>
                            <p>Clicking “submit” after completing your order form constitutes an irrevocable acceptance
                                of these general conditions.</p>
                            <p>Consequently, you can only order products or services if you accept the terms outlined
                                below.</p>
                        </div>

                        <h2>Introduction</h2>
                        <p>The following general sales conditions govern all transactions on the LYNX  web catalog.
                            Any order placed on this site implies the customer’s unconditional and irrevocable
                            acceptance of these conditions.</p>

                        <h2>Purpose</h2>
                        <p>This remote contract aims to define the rights and obligations of the parties concerning the
                            sale of products by LYNX  on the Internet, via the secure online payment platform of the
                            Interbank Monetary Center.</p>
                        <p>nyscollection.ma is a service and a registered trademark of LYNX .</p>

                        <h2>Definitions</h2>
                        <ul>
                            <li><strong>"Remote contract"</strong>: any contract for goods or services between LYNX 
                                and a Consumer within the framework of a remote sales system organized by LYNX ,
                                which exclusively uses the Internet until the conclusion of the contract, including the
                                conclusion itself.</li>
                            <li><strong>"Consumer"</strong>: any individual acting for purposes outside their
                                professional activity.</li>
                            <li><strong>"Order form"</strong>: a document indicating the characteristics of the Products
                                ordered by the Consumer and must be signed by them via “double-click” to confirm.</li>
                            <li><strong>"Order"</strong>: an act by which the Consumer commits to purchase Products, and
                                LYNX  commits to deliver them.</li>
                        </ul>

                        <h2>Products</h2>
                        <p>All products listed in the catalog are available until stocks are depleted. LYNX 
                            reserves the right to remove any item from the catalog without prior notice. The company
                            cannot be held responsible for any damages or cancellations due to the unavailability of a
                            product caused by compatibility issues with the buyer's existing equipment.</p>

                        <h2>Placing an Order</h2>
                        <p>After selecting the products of interest, click the "order" button to add the item to your
                            order form. You can modify your order form at any time and return to the catalog by clicking
                            "continue shopping."</p>
                        <p>When you have completed your shopping, finalize your order by clicking the "pay" button.</p>
                        <p>If you make an error when entering your order, we recommend you send an email request for
                            cancellation within 24 hours. After LYNX  cancels the order, you can place your order
                            again.</p>

                        <h2>Prices</h2>
                        <p>Prices listed in the catalog are in Moroccan dirhams, including all taxes, and are valid at
                            the time of customer consultation and at the time of order placement.</p>
                        <p>VAT is calculated on the order form based on the applicable rate on the order date. Orders
                            and transactions are conducted in Moroccan dirhams.</p>
                        <p>Any change in the rate will automatically be reflected in the product prices.</p>

                        <h2>Order Execution</h2>
                        <p>Orders will be executed within 7 days following the day the Consumer placed the order.</p>
                        <p>In case of product unavailability, the Consumer will be informed as soon as possible and may
                            cancel the order.</p>
                        <p>The Consumer can then choose to request a refund within thirty days or exchange the product.
                        </p>

                        <h2>Delivery</h2>
                        <p>Delivery is not managed by LYNX . Customers will collect their merchandise at the
                            delivery address indicated on their Order form.</p>
                        <p>Products are delivered to the address provided by the Consumer on the Order form. The
                            Consumer must check the goods upon delivery and report any transport damages to the carrier
                            on the delivery slip and to LYNX  within one day.</p>
                        <p>Upon request, the Consumer can receive an invoice at the delivery address by selecting the
                            option on the Order form.</p>

                        <h3>Delivery Costs:</h3>
                        <p>Transport costs are borne by the buyer. Within Morocco and internationally, they are based on
                            the Moroccan Postal Service's rates for registered mail. Prices include all taxes and are
                            calculated based on product weight. The transport cost of your delivery is indicated on your
                            invoice. Grouping orders is recommended to reduce delivery costs.</p>

                        <h3>Delivery Times:</h3>
                        <p>After receiving your payment, your order is shipped within 48 hours, subject to stock
                            availability.</p>
                        <p>Within Morocco and internationally, items are sent by registered mail. Delays do not entitle
                            the customer to damages, order cancellation, or refusal of delivery.</p>

                        <h2>Payment Methods</h2>
                        <p>To pay for your order, choose from the payment methods offered by LYNX  on the payment
                            page.</p>
                        <p>Transactions are processed for debit on the day following the confirmation of delivery.</p>
                        <p>Your online payments are secured by the Interbank Monetary Center (CMI), providing fully
                            secure payment services.</p>
                        <p>The Consumer guarantees LYNX  that they have the necessary authorizations to use the
                            chosen payment method when validating the Order form.</p>
                        <p>In the case of payment by credit card, provisions regarding the fraudulent use of the payment
                            method, as outlined in agreements between the Consumer and the card issuer and between LYNX
                             and its banking institutions, apply.</p>

                        <h2>Data Confidentiality</h2>
                        <p>Information requested is necessary for order processing and is treated confidentially. You
                            have the right to correct your personal data.</p>

                        <h2>Exchange Policy</h2>
                        <p>If you are not satisfied with the chosen product, you can exchange it for another product
                            within 15 days. Only goods returned in perfect resale condition, complete, and in their
                            original (unopened) packaging can be exchanged.</p>

                        <h2>Warranty</h2>
                        <p>All products sold in the catalog are guaranteed against manufacturing defects and material
                            flaws for 15 days. This warranty does not cover damage from accidents, misuse, or neglect.
                        </p>
                        <p>The LYNX Eyewear range comes with a conditional lifetime warranty. An ELITE pair can be
                            exchanged at any time for a pair of the same style by paying an exchange fee of 250 DHS. All
                            requests are subject to product availability. If the requested item is out of stock or
                            unavailable, LYNX Collection will provide a similar style.</p>

                        <h2>Proof of Transactions by Credit Card</h2>
                        <p>Data recorded by the CMI on the online payment platform for LYNX  constitutes proof of
                            commercial transactions between you and LYNX .</p>

                        <h2>Force Majeure</h2>
                        <p>LYNX  is only obligated to fulfill its duties to the extent that no force majeure event
                            hinders them.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'TermsPage',
}
</script>