<template>
    <ProductPage :productId="$route.params.id"/>
</template>
<script>
import ProductPage from '../components/shop/ProductPage.vue';


export default {
  name: 'ProductView',
  components:{
    ProductPage
  }
}
</script>