import axios from "axios";
import { API_URL } from "./apis";

// Fonction pour démarrer le processus de rafraîchissement du jeton
async function startTokenRefresh() {
  const tokenLc = localStorage.getItem('token');
  if (!tokenLc) {
    //console.error('Token not found in localStorage');
    return;
  }

  // Récupérer la date de la dernière exécution depuis le localStorage
  const lastExecution = localStorage.getItem('lastExecution');

  // Si lastExecution n'est pas défini ou si une heure s'est écoulée depuis la dernière exécution
  if (!lastExecution || Date.now() - lastExecution >= 3600000) { // 3600000 millisecondes = 1 heure
    // Exécutez votre logique de rafraîchissement du jeton ici
    //console.log("Le processus de rafraîchissement du jeton a démarré.");
    try {
      // Effectuer une requête pour renouveler le token
      const response = await axios.post(API_URL+'/refresh-token', {}, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${tokenLc}`,
        },
      });
      //console.log(response.data);
      // Stocker le nouveau token dans le local storage
      localStorage.setItem('token', response.data.access_token);
      // Enregistrer la date et l'heure actuelles dans le local storage comme dernière heure de rafraîchissement
      localStorage.setItem('lastRefresh', Date.now());
    } catch (error) {
      console.error('Erreur lors du renouvellement du token :', error);
    }
    // Enregistrez la nouvelle date d'exécution dans le localStorage
    localStorage.setItem('lastExecution', Date.now());
  } else {
    // Si moins d'une heure s'est écoulée depuis la dernière exécution
    console.log("...charging");
  }
}

// Appeler startTokenRefresh() au chargement du script
startTokenRefresh();

// Définir un temporisateur pour exécuter startTokenRefresh() toutes les heures
const interval = 60 * 60 * 1000; // 1 heure en millisecondes
setInterval(startTokenRefresh, interval);

export { startTokenRefresh };
