<template>
    <div class="container mt-5">
        <h1 class="mb-4 profile-title">{{$t('Profile')}} {{$t('Dashboard')}}</h1>
        <div class="row">
            <div class="col-md-3">
                <!-- Sidebar -->
                <div class="list-group">
                    <a href="#" class="list-group-item list-group-item-action active"
                        @click="selectedTab = 'details'">{{$t('Details')}}</a>
                    <a href="#" class="list-group-item list-group-item-action"
                        @click="selectedTab = 'modifyPassword'">{{$t('Modify')}} {{$t('Password')}}</a>
                    <a href="#" class="list-group-item list-group-item-action"
                        @click="selectedTab = 'modifyEmail'">{{$t('Modify')}} {{$t('Email')}}</a>
                    <a href="#" class="list-group-item list-group-item-action"
                        @click="selectedTab = 'orders'">{{$t('Orders')}}</a>
                </div>
            </div>
            <div class="col-md-9">
                <!-- Main content area -->
                <div v-if="selectedTab === 'details'">
                    <h2>{{$t('Details')}}</h2>
                    <form @submit.prevent="storeDetailUser">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="checkout__input">
                                            <p>{{$t('First Name')}}<span>*</span></p>
                                            <input type="text" v-model="billingData.name" :placeholder="$t('First Name')">
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="checkout__input">
                                            <p>{{$t('Last Name')}}<span>*</span></p>
                                            <input type="text" v-model="billingData.surnom" :placeholder="$t('Last Name')">
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="checkout__input">
                                            <p>{{$t('City')}}<span>*</span></p>
                                            <input type="text" v-model="billingData.ville" :placeholder="$t('City')">
                                        </div>
                                    </div>
                                </div>

                                <div class="checkout__input">
                                    <p>{{$t('Address')}}<span>*</span></p>
                                    <input type="text" :placeholder="$t('Street Address')" class="checkout__input__add"
                                        v-model="billingData.adresse">
                                    <input type="text" :placeholder="$t('Apartment, suite, unite ect (optinal)')"
                                        v-model="billingData.adrese_complete">
                                </div>

                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="checkout__input">
                                            <p>{{$t('Phone')}}<span>*</span></p>
                                            <input type="text" v-model="billingData.phone" placeholder="XX XX XXXX XX">
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="checkout__input">
                                            <p>{{$t('Postcode')}} / {{$t('ZIP')}}<span>*</span></p>
                                            <input type="text" v-model="billingData.code_postal"
                                                :placeholder="$t('Postal Code')">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center"><button type="submit" class="site-btn">{{$t('Save')}}</button></div>

                    </form>
                </div>
                <div v-else-if="selectedTab === 'modifyPassword'">
                    <!-- Modify Password form -->
                    <h2>{{$t('Modify')}} {{$t('Password')}}</h2>
                    <!-- Implement password modification form -->
                    <form @submit.prevent="modifyPasswordUser">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="checkout__input">
                                            <p>{{$t('Current password')}}<span>*</span></p>
                                            <input type="password" v-model="userPasswordData.currentPassword"
                                                :placeholder="$t('Current password')">
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="checkout__input">
                                            <p>{{$t('New password')}}<span>*</span></p>
                                            <input type="password" v-model="userPasswordData.newPassword"
                                                :placeholder="$t('New password')">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center"><button type="submit" class="site-btn">{{$t('Save')}}</button></div>

                    </form>
                </div>
                <div v-else-if="selectedTab === 'modifyEmail'">
                    <!-- Modify Email form -->
                    <h2>{{$t('Modify')}} {{$t('Email')}}</h2>
                    <form @submit.prevent="modifyEmailUser">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="checkout__input">
                                            <p>{{$t('Current password')}}<span>*</span></p>
                                            <input type="password" v-model="userEmailData.currentPassword"
                                                :placeholder="$t('Current password')">
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="checkout__input">
                                            <p>{{$t('New Email')}}<span>*</span></p>
                                            <input type="email" v-model="userEmailData.email" :placeholder="$t('New Email')">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center"><button type="submit" class="site-btn">{{$t('Save')}}</button></div>

                    </form>
                </div>
                <div v-else-if="selectedTab === 'orders'">
                    <!-- Display orders history -->
                    <h2>{{$t('Orders')}}</h2>
                    <table class="table table-striped table-dark">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Ref</th>
                                <th scope="col">{{$t('Track number')}}</th>
                                <th scope="col">{{$t('Total')}}</th>
                                <th scope="col">{{$t('State')}}</th>
                                <th scope="">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(order,index) in user.commandes" :key="order.id">
                                <th scope="row">{{ index + 1 }}</th>
                                <td>{{ order.ref }}</td>
                                <td>{{ order.track_number }}</td>
                                <td>{{ order.prix_total }}</td>
                                <td>
                                        <span class="badge bg-secondary" v-if="order.etat == 0">{{$t('WAITING')}}</span>
                                        <span class="badge bg-primary" v-if="order.etat == 1">{{$t('CONFIRMED')}}</span>
                                        <span class="badge bg-warning" v-if="order.etat == 2">{{$t('LIVRED')}}</span>
                                        <span class="badge bg-success" v-if="order.etat == 3">{{$t('PAID')}}</span>
                                        <span class="badge bg-danger" v-if="order.etat == 4">{{$t('CANCELED')}}</span>
                                </td>
                                <td><span class="btn btn-danger" v-if="order.etat == 0" @click="cancelOrderFromUser(order.id)">{{$t('Delete')}}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';
import { API_URL } from '@/services/apis';
export default {
    name: 'ProfilePage',
    setup() {
        const user = ref({});
        const selectedTab = ref('details');
        const token = localStorage.getItem('token');
        const userData = JSON.parse(localStorage.getItem('userData'));
        const { t } = useI18n();
        const billingData = ref({
            id: 0,
            name: '',
            surnom: '',
            ville: '',
            adresse: '',
            adrese_complete: '',
            code_postal: '',
            phone: '',
        });
        const userPasswordData = ref({
            currentPassword: '',
            newPassword: ''
        });
        const userEmailData = ref({
            currentPassword: '',
            email: ''
        });
        // Fetch user data on component mount
        onMounted(async () => {
            try {
                const response = await axios.get(API_URL+`/profile/${userData.user.id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                user.value = response.data.data;
                if (response.data.data.detail) {
                    billingData.value.id = response.data.data.detail.id;
                    billingData.value.name = response.data.data.detail.name;
                    billingData.value.surnom = response.data.data.detail.surnom;
                    billingData.value.ville = response.data.data.detail.ville;
                    billingData.value.adresse = response.data.data.detail.adresse;
                    billingData.value.adrese_complete = response.data.data.detail.adrese_complete;
                    billingData.value.phone = response.data.data.detail.phone;
                    billingData.value.code_postal = response.data.data.detail.code_postal;
                }


            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        });
        const storeDetailUser = async () => {
            const detailUserData = {
                details: billingData.value
            };
            try {
                const response = await fetch(API_URL+`/profile/detail/add/${userData.user.id}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(detailUserData)
                });
                const data = await response.json();
                if (!response.ok) {
                    throw new Error('Failed to place the order');
                } else {
                    onMounted()
                    console.log(data)
                }

            } catch (error) {
                console.error('Error placing order:', error.message);
            }
        };
        const modifyPasswordUser = async () => {
            const token = localStorage.getItem('token');
            const detailUserData = {
                details: userPasswordData.value
            };
            try {
                const response = await fetch(API_URL+`/profile/detail/password/modify/${userData.user.id}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(detailUserData)
                });
                const data = await response.json();
                if (!response.ok) {
                    throw new Error(data.error || 'Failed to modify the password');
                } else {
                    console.log(data);
                    Swal.fire('Password Modified', '', 'success');
                }
            } catch (error) {
                console.error('Error modifying password:', error.message);
                Swal.fire('Error', error.message, 'error');
            }
        };
        const modifyEmailUser = async () => {
            const token = localStorage.getItem('token');
            const detailUserData = {
                details: userEmailData.value
            };
            try {
                const response = await fetch(API_URL+`/profile/detail/email/modify/${userData.user.id}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(detailUserData)
                });
                const data = await response.json();
                if (!response.ok) {
                    throw new Error(data.error || 'Failed to modify the email');
                } else {
                    console.log(data);
                    Swal.fire('Email Modified', '', 'success');
                }
            } catch (error) {
                console.error('Error modifying email:', error.message);
                Swal.fire('Error', error.message, 'error');
            }
        };
        const cancelOrderFromUser = async (id) => {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(API_URL+`/profile/detail/order/cancel/${userData.user.id}/${id}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                if (!response.ok) {
                    throw new Error(data.error || 'Failed to cancel the email');
                } else {
                    Swal.fire('Order Canceled', '', 'success');
                    window.location.reload();
                }
            } catch (error) {
                console.error('Error cancel lorder:', error.message);
                Swal.fire('Error', error.message, 'error');
            }
        };
        return { user, selectedTab, billingData, userPasswordData, userEmailData, storeDetailUser, modifyPasswordUser, modifyEmailUser,cancelOrderFromUser,t };
    }
}
</script>