<template>
    <div class="container">

        <div class="row text-center">
            <div class="col-lg-12 col-md-12 ">
                <div class="checkout__order">
                    <h4 class="order__title">{{$t('Track your order')}}</h4>
                    <div class="mb-3">
                        <h5>{{$t('Enter your')}}<b class="text-danger">{{$t('Track number')}}</b>. {{$t("Its on your order confirmation or dispatch")}} <b class="text-danger">{{$t('Email')}}</b>.</h5>
                    </div>
                    <div v-if="errorMessages.length" class="alert alert-danger" role="alert">
                        <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Warning:">
                            <use xlink:href="#exclamation-triangle-fill" />
                        </svg>
                        <ul>
                            <span v-for="(message, index) in errorMessages" :key="index">
                                <i class="bi bi-exclamation-circle-fill"></i> {{ message }}<br>
                            </span>
                        </ul>
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <form @submit.prevent="placeTrackOrderCheck">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="checkout__input">
                                    <p>{{$t('Track Number')}}<span>*</span></p>
                                    <input type="text" v-model="trackData.track_number">
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="checkout__input">
                                    <p>{{$t('Email')}}<span>*</span></p>
                                    <input type="text" v-model="trackData.email">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 text-center"><button type="submit" class="site-btn">{{$t('Find')}}
                                    {{$t('Order')}}</button></div>
                        </div>

                    </form>
                    <div v-if="commande">
                        <div class="mb-3 mt-3">
                            <h5>{{$t('Order')}} {{$t('Founded')}}</h5>
                        </div>
                        <table class="table table-dark table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">{{$t('Order')}}</th>
                                    <th scope="col">Ref</th>
                                    <th scope="col">{{$t('State')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">{{ commande.id }}</th>
                                    <td>{{ commande.ref }}</td>
                                    <td>
                                        <span class="badge bg-secondary" v-if="commande.etat == 0">{{$t('WAITING')}}</span>
                                        <span class="badge bg-primary" v-if="commande.etat == 1">{{$t('CONFIRMED')}}</span>
                                        <span class="badge bg-warning" v-if="commande.etat == 2">{{$t('LIVRED')}}</span>
                                        <span class="badge bg-success" v-if="commande.etat == 3">{{$t('PAID')}}</span>
                                        <span class="badge bg-danger" v-if="commande.etat == 4">{{$t('CANCELED')}}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-if="!commandeNotFound">
                        <span><b class="text-danger">{{$t("track_not_found")}}.</b></span>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>
<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { API_URL } from '@/services/apis';
import { useHead } from '@vueuse/head';
export default {
    name: 'TrackPage',
    setup() {
        useHead({
			title: 'LYNX | Acheter Lunettes de Soleil au Maroc - Votre Boutique en Ligne - Tracking Order LYNX',
			meta: [
				{ name: 'description', content: 'LYNX Découvrez notre collection de lunettes de soleil tendance au Maroc. Protection UV, matériaux de qualité, et livraison rapide. Achetez maintenant!' },
				{ name: 'keywords', content: 'LYNX,lynx,look different,acheter lunettes de soleil Maroc, lunettes de soleil tendance, protection UV, lunettes en bois, lunettes de vue Maroc' }
			]
		});
        const isConnected = localStorage.getItem('isConnected');
        const store = useStore();
        const commande = ref(null)
        const commandeNotFound = ref(true)
        const cartItems = computed(() => store.getters.cartItems);
        const cartTotal = computed(() => store.getters.cartTotal);
        const { t } = useI18n();
        const trackData = ref({
            email: '',
            track_number: ''
        });
        const errorMessages = ref([]);

        const validateForm = () => {
            errorMessages.value = [];
            const requiredFields = ['email', 'track_number'];

            for (const field of requiredFields) {
                if (!trackData.value[field]) {
                    errorMessages.value.push(`The field ${field.replace('_', ' ')} is required  .`);
                }
            }
            return errorMessages.value.length === 0;
        };
        const placeTrackOrderCheck = () => {
            if (!validateForm()) {
                return;
            }
            trackOrder()
        }
        const trackOrder = async () => {
            const orderTrackData = {
                details: trackData.value
            };
            //console.log(orderTrackData)
            try {
                const response = await fetch(API_URL+'/commande/trackorder', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(orderTrackData)
                });
                const data = await response.json();
                if (!response.ok) {
                    commandeNotFound.value = false;
                    throw new Error('Failed to place the order');
                } else {
                    //console.log('inside place order')
                    commande.value = data.data
                    commandeNotFound.value = true;
                }

                // Clear the cart after placing the order
                //store.dispatch('clearCart');
            } catch (error) {
                console.error('Error tracking order:', error.message);
            }
        };
        return {
            cartItems,
            cartTotal,
            trackOrder,
            trackData,
            placeTrackOrderCheck,
            isConnected,
            errorMessages,
            commande,
            commandeNotFound,
            t
        };
    },
};
</script>

<style scoped>
.checkout__order .site-btn {
    width: 30%;
    margin-top: 8px;
}
</style>