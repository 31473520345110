<template>
    <PrivacyPage />
  </template>
  <script>
import PrivacyPage from '../components/pages/PrivacyPage.vue';


  
  export default {
  name: 'PrivacyView',
  components:{
    PrivacyPage
  }
  }
  </script>