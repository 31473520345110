<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 ">
                <div class="checkout__order">
                    <h4 class="order__title">Password Reset</h4>
                    <div class="mb-3 register-info">
                        <h5>If you don't have an account you can create account
                            <b class="text-primary">
                                <a href="/register">Register</a></b>
                        </h5>
                    </div>
                    <b class="alert alert-warning text-danger" v-if="message">{{ message }}</b>
                    <form @submit.prevent="resetPassword">
                        <input type="hidden" v-model="token" />
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="checkout__input">
                                    <p>Email<span>*</span></p>
                                    <input type="email" class="form-control" id="email" v-model="email"
                                        placeholder="Email" required />
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="checkout__input">
                                    <p>New Password<span>*</span></p>
                                    <input type="password" class="form-control" id="password" v-model="password"
                                        placeholder="New Password" required />
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="checkout__input">
                                    <p>Confim Password<span>*</span></p>
                                    <input type="password" class="form-control" id="password_confirmation" v-model="password_confirmation"
                                        placeholder="Confirm Password" required />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 text-center"><button type="submit" class="site-btn"
                                    :disabled="loading">Reset Password</button></div>
                        </div>
                        <div v-if="error" class="alert alert-danger mt-3">{{ error }}</div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { useRoute } from 'vue-router';
import { API_URL } from '@/services/apis';

export default {
    data() {
        return {
            email: '',
            password: '',
            password_confirmation: '',
            token: '',
            message: '',
        };
    },
    setup() {
        const route = useRoute();
        return {
            tokenS: route.query.token,
            emailS: route.query.email,
        };
    },
    methods: {
        async resetPassword() {
            try {
                const response = await axios.post(API_URL + '/password/reset', {
                    email: this.emailS,
                    password: this.password,
                    password_confirmation: this.password_confirmation,
                    token: this.tokenS,
                });
                this.message = response.data.message;
                if(this.message){
                    setTimeout(() => {
                        this.$router.push('/login');
                    }, 2500);
                }
                
            } catch (error) {
                this.message = error.response.data.message;
            }
        },
    },
};
</script>