<template>
	<header class="header">
		<!-- Header Top -->
		<div class="header__top">
			<div class="container">
				<div class="row">
					<div class="col-lg-2 col-md-7">
						<div class="header__top__left">
							<p> <i class="fa-solid fa-phone"></i> (+212) 655 69 7226</p>

						</div>
					</div>
					<div class="col-lg-4 col-md-7">
						<div class="header__top__left">
							<p>{{ $t('free_shipping') }}</p>
						</div>
					</div>
					<div class="col-lg-6 col-md-5" v-if="!userData">
						<div class="header__top__right">
							<div class="header__top__links">
								<a href="/login">{{ $t('sign_in') }}</a>
								<a href="/register">{{ $t('register') }}</a>
								<span class="mr-5" @click="switchLanguage('en')" title="EN"
									style="cursor: pointer;"><img src="../../assets/img/flag/uk.png" /></span> |
								<span class="ml-5" @click="switchLanguage('fr')" title="FR"
									style="cursor: pointer;"><img src="../../assets/img/flag/fr.png" /></span>
							</div>
						</div>
					</div>
					<div class="col-lg-6 col-md-5" v-else>
						<div class="header__top__right">
							<div class="header__top__links">
								<span class="text-white">{{ $t('welcome_back') }} : <a href="/profile"
										class="text-warning" :title="$t('profile')"><b>{{ userData.user.name }}</b><i
											class="fa-solid fa-user"></i></a> | </span>
								<a href="#" class="text-danger" @click="Logout" :title="$t('logout')"><i
										class="fa-solid fa-right-from-bracket"></i></a>
								<span class="mr-5" @click="switchLanguage('en')" title="EN"
									style="cursor: pointer;"><img src="../../assets/img/flag/uk.png" /></span> |
								<span class="ml-5" @click="switchLanguage('fr')" title="FR"
									style="cursor: pointer;"><img src="../../assets/img/flag/fr.png" /></span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Main Navigation -->
<div class="container">
    <div class="row align-items-center">
        <div class="col-lg-3 col-md-3 col-6">
            <div class="header__logo">
                <a href="/"><img src="../../assets/img/lynx/lynx-icon.png" alt=""
                        style="width:70px;height:100px;"></a>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 d-none d-md-block">
            <nav class="header__menu mobile-menu">
                <MenuView />
                <!--<ul>
                    <li class="active"><a href="/">{{ $t('home') }}</a></li>
                    <li><a href="/shop">{{ $t('shop') }}</a></li>
                    <li><a href="/track-order">{{ $t('Track Order') }}</a></li>
                    <li><a href="/contact">{{ $t('contact') }}</a></li>
                </ul>-->
            </nav>
        </div>
        <div class="col-lg-3 col-md-3 col-6">
            <div class="header__nav__option">
                <a href="#" class="search-switch" data-bs-toggle="modal" data-bs-target="#searchModal"><img
                        src="../../assets/img/icon/search.png" alt=""></a>
                <!-- Modal -->
                <div class="modal fade search-modal" id="searchModal" tabindex="-1"
                    aria-labelledby="searchModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content search-modal-content">
                            <div class="modal-header search-modal-header">
                                <h5 class="modal-title text-white" id="searchModalLabel">{{$t('Search Product')}}</h5>
                                <button type="button" class="btn-close text-white" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body search-modal-body">
                                <SearchView />
                            </div>
                            <div class="modal-footer search-modal-footer">
                                <button type="button" class="btn btn-secondary"
                                    data-bs-dismiss="modal">{{$t('Close')}}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="/whishlist"><img src="../../assets/img/icon/heart.png" alt=""><span
                        class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{{
                        whishlistCount }}<span class="visually-hidden">{{ $t('products') }}</span></span></a>
                <a href="#" @click="toggleCart"><img src="../../assets/img/icon/cart.png" alt=""><span
                        class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{{
                        cartCount
                    }}<span class="visually-hidden">{{ $t('products') }}</span></span></a>
                <div class="price">{{ cartTotal }} DH</div>
            </div>
        </div>
    </div>
    <div class="canvas__open d-md-none">
        <span class="" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-bars"></i>
        </span>
        <ul class="dropdown-menu p-3">
            <div class="d-flex justify-content-center mb-3">
                <a href="/whishlist" class="text-dark mx-2"><i class="fa fa-heart">{{ whishlistCount }}</i></a>
                <a href="/shopping-cart" class="text-dark mx-2"><i class="fa-solid fa-bag-shopping">{{ cartCount }}</i>
                    <span class="visually-hidden">{{ $t('products') }}</span></a>
            </div>
            <li><a class="dropdown-item" href="/">{{ $t('home') }}</a></li>
            <li><a class="dropdown-item" href="/shop">{{ $t('shop') }}</a></li>
            <li><a class="dropdown-item" href="/track-order">{{ $t('Track Order') }}</a></li>
            <li><a class="dropdown-item" href="/contact">{{ $t('contact') }}</a></li>
            <li v-if="userData"><a class="dropdown-item text-warning" href="/profile" :title="$t('profile')"><i
                        class="fa-solid fa-user"> {{ userData.user.name }}</i></a></li>
            <div class="dropdown-divider"></div>
            <div class="d-flex justify-content-center mt-3">
                <a v-if="!userData" href="/login" class="btn btn-outline-dark mx-2" :title="$t('sign_in')"><i
                        class="fa-solid fa-right-to-bracket"></i></a>
                <a v-if="userData" href="#" class="btn btn-outline-danger mx-2" @click="Logout"
                    :title="$t('logout')"><i class="fa-solid fa-right-from-bracket"></i></a>
                <span class="mr-5" @click="switchLanguage('en')" title="EN" style="cursor: pointer;"><img
                        src="../../assets/img/flag/uk.png" /></span> |
                <span class="ml-5" @click="switchLanguage('fr')" title="FR" style="cursor: pointer;"><img
                        src="../../assets/img/flag/fr.png" /></span>
            </div>
        </ul>
    </div>
</div>

		<!-- Search Begin -->
		<div class="search-model" v-show="isOpenedSearch">
			<div class="h-100 d-flex align-items-center justify-content-center">
				<div class="search-close-switch">+</div>
				<form class="search-model-form">
					<input type="text" id="search-input" placeholder="Search here.....">
				</form>
			</div>
		</div>
		<!-- Search End -->
	</header>
	<transition name="cart-fade">
		<div v-if="isCartOpen" class="cart-dropdown">
			<div class="shopping-cart">
				<div class="shopping-cart-header">
					<i class="fa fa-shopping-cart cart-icon"></i><span class="badge">{{ cartCount }}</span>
					<div class="shopping-cart-total">
						<span class="lighter-text">{{ $t('total') }}:</span>
						<span class="main-color-text">{{ cartTotal }} DH</span>
					</div>
				</div>
				<ul class="shopping-cart-items">
					<li class="clearfix" v-for="(item, index) in cartItems" :key="index">

						<img :src="storageUrl+'/' + item.picture" alt="item1"
							style="width: 70px;height: 70px;" />
						<span class="item-name">{{ item.name }}</span>
						<span class="item-price">{{ item.prix * item.quantity }} DH</span>
						<span class="item-quantity">{{ $t('quantity') }}: {{ item.quantity }}</span>

						<!--<div v-if="item.selectedData">
							<div v-for="(option, optionId) in item.selectedData" :key="optionId">
								{{ option }}
								<span class="item-option">
									
								</span>
							</div>
						</div>-->
						<button @click="removeFromCart(item.id)" class="btn btn-danger btn-sm">{{ $t('remove')
							}}</button><br />
					</li>
				</ul>
				<div class="text-center mt-3">
					<button @click="clearCart" class="btn btn-warning">{{ $t('clear_cart') }}</button>
				</div>
				<a href="/shopping-cart" class="button">{{ $t('checkout') }}</a>
			</div>
		</div>
	</transition>

</template>

<script>
import { computed, ref } from 'vue';
import { useStore, mapActions, mapGetters } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useHead } from '@vueuse/head';
import { API_URL,STORAGE_URL } from '@/services/apis';
import Swal from 'sweetalert2';
import MenuView from './MenuView.vue';
import SearchView from './SearchView.vue';
export default {
	name: 'HeaderView',
	components: {
		MenuView,
		SearchView
	},
	methods: {
		...mapActions(['fetchDataFromDatabase']), // Mappez l'action fetchDataFromDatabase à une méthode dans votre composant
		async fetchData() {
			// Appeler l'action pour récupérer les données
			await this.fetchDataFromDatabase();
			// Les données sont maintenant disponibles dans votre magasin Vuex et peuvent être utilisées via des getters dans votre composant
		},
		getOptionName(optionId) {
			console.log(optionId)
			const product = this.cartItems.find(item => item.selectedOptions[optionId]);
			console.log(product)
			const option = product.options.find(opt => opt.id == optionId);
			return option ? option.name : '';
		},
		getOptionValueName(optionValeurId) {
			const product = this.cartItems.find(item => {
				return Object.values(item.selectedOptions).some(opt => opt.optionValeurId === optionValeurId);
			});
			if (product) {
				for (const option of product.options) {
					const optionValeur = option.optionvaleurs.find(val => val.id === optionValeurId);
					if (optionValeur) {
						return optionValeur.name;
					}
				}
			}
			return '';
		}
	},
	mounted() {
		this.fetchData();
	},
	computed: {
		storageUrl() {
            return STORAGE_URL;
        },
		...mapGetters([
			'adresse_store',
			'phone_store',
			'logo_store',
			'nav_text',
			'footer_text',
			'email_store',
			'instagram_store',
			'facebook_store',
			'whatsapp_store',
			// Ajoutez d'autres getters pour les données supplémentaires si nécessaire
		]),
	},
	setup() {
		useHead({
			title: 'LYNX | Acheter Lunettes de Soleil au Maroc - Votre Boutique en Ligne',
			meta: [
				{ name: 'description', content: 'LYNX Découvrez notre collection de lunettes de soleil tendance au Maroc. Protection UV, matériaux de qualité, et livraison rapide. Achetez maintenant!' },
				{ name: 'keywords', content: 'LYNX,lynx,look different,acheter lunettes de soleil Maroc, lunettes de soleil tendance, protection UV, lunettes en bois, lunettes de vue Maroc' }
			]
		});
		const { t, locale } = useI18n();
		const store = useStore();
		const isCartOpen = ref(false);
		const isMobileMenuOpen = ref(false);

		const toggleCart = () => {
			isCartOpen.value = !isCartOpen.value;
		};
		const toggleMobileMenu = () => {
			console.log('here')
			isMobileMenuOpen.value = !isMobileMenuOpen.value;
		};

		//Cart
		const cartItems = computed(() => store.getters.cartItems);
		const cartCount = computed(() => store.getters.cartItems.length);
		const cartTotal = computed(() => store.getters.cartItems.reduce((total, item) => total + (item.prix * item.quantity), 0));
		//Whishlist
		const whishlistItems = computed(() => store.getters.whishlistItems);
		const whishlistCount = computed(() => store.getters.whishlistCount);

		const userData = JSON.parse(localStorage.getItem('userData'));
		const isOpenedSearch = ref(false);
		const openSearch = () => {
			isOpenedSearch.value = true;
		};
		const closeSearch = () => {
			isOpenedSearch.value = false;
		};
		const Logout = async () => {
			const token = localStorage.getItem('token');
			try {
				const response = await fetch(API_URL + '/logout', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
				});
				console.log('logout')
				const data = await response.json();
				console.log(data)
				localStorage.setItem('isConnected', '0');
				localStorage.setItem('userData', 'null');
				localStorage.setItem('token', 'null');
				window.location.reload();
				Swal.fire('', 'See you soon :)', '')
			} catch (error) {
				console.error('Error placing order:', error.message);
			}
		};
		const removeFromCart = (productId) => {
			store.dispatch('removeFromCart', productId);
		};

		const clearCart = () => {
			store.dispatch('clearCart');
		};

		const switchLanguage = (lang) => {
			locale.value = lang;
			localStorage.setItem('locale', lang);
		};

		return {
			isCartOpen,
			toggleCart,
			cartItems,
			cartCount,
			cartTotal,
			userData,
			Logout,
			removeFromCart,
			clearCart,
			switchLanguage,
			t,
			whishlistItems,
			whishlistCount,
			isMobileMenuOpen,
			toggleMobileMenu,
			openSearch,
			closeSearch,
			isOpenedSearch

		};
	},
};
</script>

<style scoped>
.cart-dropdown {
	position: absolute;
	right: 1px;
	top: 119px;
	width: 320px;
	z-index: 1000;
}

.cart-fade-enter-active,
.cart-fade-leave-active {
	transition: opacity 0.5s;
}

.cart-fade-enter,
.cart-fade-leave-to {
	opacity: 0;
}

.shopping-cart {
	margin: 20px 0;
	float: right;
	background: white;
	width: 320px;
	position: relative;
	border-radius: 3px;
	padding: 20px;
}

.shopping-cart .shopping-cart-header {
	border-bottom: 1px solid #e8e8e8;
	padding-bottom: 15px;
}

.shopping-cart .shopping-cart-header .shopping-cart-total {
	float: right;
}

.shopping-cart .shopping-cart-items {
	padding-top: 20px;
}

.shopping-cart .shopping-cart-items li {
	margin-bottom: 18px;
}

.shopping-cart .shopping-cart-items img {
	float: left;
	margin-right: 12px;
}

.shopping-cart .shopping-cart-items .item-name {
	display: block;
	padding-top: 10px;
	font-size: 16px;
}

.shopping-cart .shopping-cart-items .item-price {
	color: #6394f8;
	margin-right: 8px;
}

.shopping-cart .shopping-cart-items .item-quantity {
	color: #abb0be;
}

.shopping-cart:after {
	bottom: 100%;
	left: 89%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-bottom-color: white;
	border-width: 8px;
	margin-left: -8px;
}

.cart-icon {
	color: #515783;
	font-size: 24px;
	margin-right: 7px;
	float: left;
}

.button {
	background: #6394f8;
	color: white;
	text-align: center;
	padding: 12px;
	text-decoration: none;
	display: block;
	border-radius: 3px;
	font-size: 16px;
	margin: 25px 0 15px 0;
}

.button:hover {
	background: #729ef9;
}

.clearfix:after {
	content: "";
	display: table;
	clear: both;
}

/*--------------------------------- Responsive Media Quaries -----------------------------*/

@media only screen and (min-width: 1200px) {
	.container {
		max-width: 1170px;
	}
}

/* Medium Device = 1200px */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.categories__text h2 {
		font-size: 26px;
	}

	.header__menu ul li {
		margin-right: 38px;
	}

	.hero__slider.owl-carousel .owl-nav button {
		left: 2px;
	}

	.hero__slider.owl-carousel .owl-nav button.owl-next {
		right: 2px;
	}

	.testimonial__text {
		padding: 130px 45px 175px;
	}
}

/* Tablet Device = 768px */

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.header__menu ul li {
		margin-right: 10px;
	}

	.header__nav__option a {
		margin-right: 10px;
	}

	.header__nav__option .price {
		margin-left: 0;
	}

	.hero__slider.owl-carousel .owl-nav button {
		left: 2px;
	}

	.hero__slider.owl-carousel .owl-nav button.owl-next {
		right: 2px;
	}

	.banner__item.banner__item--middle {
		margin-top: 0;
	}

	.banner__item.banner__item--last {
		margin-top: 0;
	}

	.banner__item {
		margin-bottom: 40px;
	}

	.banner {
		padding-bottom: 60px;
	}

	.categories__text {
		margin-bottom: 40px;
	}

	.categories__hot__deal {
		margin-bottom: 40px;
	}

	.instagram__text {
		padding-top: 70px;
	}

	.shop__sidebar {
		padding-right: 0;
		padding-top: 40px;
	}

	.cart__discount {
		margin-top: 40px;
	}

	.testimonial__text {
		padding: 100px 105px 100px;
	}

	.blog__details__share {
		position: relative;
		left: 0;
		margin-bottom: 18px;
	}

	.blog__details__share span {
		margin-bottom: 14px;
		margin-right: 0;
	}

	.blog__details__share ul li {
		margin-bottom: 15px;
		display: inline-block;
		margin-right: 10px;
	}

	.blog__details__share ul li:last-child {
		margin-right: 0;
	}
}

/* Wide Mobile = 480px */

@media only screen and (max-width: 767px) {
	.canvas__open {
		display: block;
		font-size: 22px;
		color: #111111;
		height: 35px;
		width: 35px;
		line-height: 35px;
		text-align: center;
		border: 1px solid #111111;
		border-radius: 2px;
		cursor: pointer;
		position: absolute;
		right: 15px;
		top: 25px;
	}

	.offcanvas-menu-overlay {
		position: fixed;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background: rgba(0, 0, 0, 0.7);
		content: "";
		z-index: 98;
		-webkit-transition: all, 0.5s;
		-o-transition: all, 0.5s;
		transition: all, 0.5s;
		visibility: hidden;
	}

	.offcanvas-menu-overlay.active {
		visibility: visible;
	}

	.offcanvas-menu-wrapper {
		position: fixed;
		left: -300px;
		width: 300px;
		height: 100%;
		background: #ffffff;
		padding: 50px 20px 30px 30px;
		display: block;
		z-index: 99;
		overflow-y: auto;
		-webkit-transition: all, 0.5s;
		-o-transition: all, 0.5s;
		transition: all, 0.5s;
		opacity: 0;
	}

	.offcanvas-menu-wrapper.active {
		opacity: 1;
		left: 0;
	}

	.offcanvas__menu {
		display: none;
	}

	.slicknav_btn {
		display: none;
	}

	.slicknav_menu {
		background: transparent;
		padding: 0;
		margin-bottom: 20px;
	}

	.slicknav_nav ul {
		margin: 0;
	}

	.slicknav_nav .slicknav_row,
	.slicknav_nav a {
		padding: 7px 0;
		margin: 0;
		color: #111111;
		font-weight: 600;
	}

	.slicknav_nav .slicknav_arrow {
		color: #111111;
	}

	.slicknav_nav .slicknav_row:hover {
		border-radius: 0;
		background: transparent;
		color: #111111;
	}

	.slicknav_nav a:hover {
		border-radius: 0;
		background: transparent;
		color: #111111;
	}

	.slicknav_nav {
		display: block !important;
	}

	.offcanvas__option {
		text-align: center;
		margin-bottom: 30px;
	}

	.offcanvas__links {
		display: inline-block;
		margin-right: 25px;
	}

	.offcanvas__links a {
		color: #111111;
		font-size: 13px;
		text-transform: uppercase;
		letter-spacing: 2px;
		margin-right: 16px;
		display: inline-block;
		font-weight: 600;
	}

	.offcanvas__links a:last-child {
		margin-right: 0;
	}

	.offcanvas__top__hover {
		display: inline-block;
		position: relative;
	}

	.offcanvas__top__hover:hover ul {
		top: 24px;
		opacity: 1;
		visibility: visible;
	}

	.offcanvas__top__hover span {
		color: #111111;
		font-size: 13px;
		text-transform: uppercase;
		letter-spacing: 2px;
		display: inline-block;
		cursor: pointer;
		font-weight: 600;
	}

	.offcanvas__top__hover span i {
		font-size: 20px;
		position: relative;
		top: 3px;
		right: 2px;
	}

	.offcanvas__top__hover ul {
		background: #111111;
		display: inline-block;
		padding: 2px 0;
		position: absolute;
		left: 0;
		top: 44px;
		opacity: 0;
		visibility: hidden;
		z-index: 3;
		-webkit-transition: all, 0.3s;
		-o-transition: all, 0.3s;
		transition: all, 0.3s;
	}

	.offcanvas__top__hover ul li {
		list-style: none;
		font-size: 13px;
		color: #ffffff;
		padding: 2px 15px;
		cursor: pointer;
	}

	.offcanvas__nav__option {
		text-align: center;
		margin-bottom: 25px;
	}

	.offcanvas__nav__option a {
		display: inline-block;
		margin-right: 26px;
		position: relative;
	}

	.offcanvas__nav__option a span {
		color: #0d0d0d;
		font-size: 11px;
		position: absolute;
		left: 5px;
		top: 8px;
	}

	.offcanvas__nav__option a:last-child {
		margin-right: 0;
	}

	.offcanvas__nav__option .price {
		font-size: 15px;
		color: #111111;
		font-weight: 700;
		display: inline-block;
		margin-left: -20px;
		position: relative;
		top: 3px;
	}

	.offcanvas__text p {
		color: #111111;
		margin-bottom: 0;
	}

	.header__top {
		display: none;
	}

	.header .container {
		position: relative;
	}

	.header__menu {
		display: none;
	}

	.header__nav__option {
		display: none;
	}

	.search-model-form input {
		width: 100%;
		font-size: 24px;
	}

	.hero__slider.owl-carousel .owl-nav button {
		left: 15px;
		top: 80%;
	}

	.hero__slider.owl-carousel .owl-nav button.owl-next {
		left: 75px;
		right: 0;
	}

	.banner__item.banner__item--middle {
		margin-top: 0;
	}

	.banner__item.banner__item--last {
		margin-top: 0;
	}

	.banner__item {
		margin-bottom: 40px;
	}

	.banner {
		padding-bottom: 60px;
	}

	.banner__item__pic {
		float: none;
	}

	.banner__item__pic img {
		min-width: 100%;
	}

	.banner__item__text {
		max-width: 100%;
		position: relative;
		top: 0;
		padding-top: 22px;
	}

	.filter__controls li {
		margin-right: 15px;
	}

	.categories__text {
		margin-bottom: 40px;
	}

	.categories__hot__deal {
		margin-bottom: 40px;
	}

	.instagram__text {
		padding-top: 70px;
	}

	.instagram__pic__item {
		width: 50%;
	}

	.shop__product__option__right {
		text-align: left;
		padding-top: 20px;
	}

	.shop__sidebar {
		padding-right: 0;
		margin-bottom: 40px;
	}

	.testimonial__text {
		padding: 100px 40px 100px;
	}

	.product__details__breadcrumb {
		text-align: left;
	}

	.product__details__pic .nav-tabs {
		width: auto;
		margin-bottom: 40px;
	}

	.product__details__pic .nav-tabs .nav-item {
		margin-bottom: 0;
		margin-right: 10px;
	}

	.product__details__option__size {
		display: block;
		margin-right: 0;
		margin-bottom: 25px;
	}

	.product__details__last__option h5:before {
		width: 440px;
	}

	.product__details__tab .nav-tabs .nav-item {
		margin-bottom: 15px;
	}

	.shopping__cart__table {
		overflow-y: auto;
	}

	.shopping__cart__table table tbody tr td.product__cart__item .product__cart__item__pic {
		float: none;
		margin-right: 0;
	}

	.continue__btn {
		text-align: center;
	}

	.continue__btn.update__btn {
		text-align: center;
		margin-top: 20px;
	}

	.cart__discount {
		margin-top: 40px;
	}

	.checkout__order {
		margin-top: 20px;
	}

	.blog__details__share {
		position: relative;
		left: 0;
		margin-bottom: 18px;
	}

	.blog__details__share span {
		margin-bottom: 14px;
		margin-right: 0;
	}

	.blog__details__share ul li {
		margin-bottom: 15px;
		display: inline-block;
		margin-right: 10px;
	}

	.blog__details__share ul li:last-child {
		margin-right: 0;
	}

	.blog__details__author {
		text-align: center;
		margin-bottom: 20px;
	}

	.blog__details__tags {
		text-align: center;
	}

	.contact__text {
		margin-bottom: 40px;
	}

	.hero__social {
		margin-top: 180px;
	}
}

/* Small Device = 320px */

@media only screen and (max-width: 479px) {
	.cart__total {
		padding: 35px 30px 40px;
	}

	.hero__items {
		height: auto;
		padding-top: 130px;
		padding-bottom: 40px;
	}

	.hero__text h2 {
		font-size: 36px;
		line-height: 48px;
	}

	.hero__social {
		margin-top: 145px;
	}

	.categories__deal__countdown .categories__deal__countdown__timer {
		margin-left: 0;
	}

	.instagram__pic__item {
		width: 100%;
	}

	.testimonial__text {
		padding: 60px 40px 60px;
	}

	.product__details__pic .nav-tabs .nav-item .nav-link .product__thumb__pic {
		width: 100%;
	}

	.product__details__pic .nav-tabs .nav-item {
		margin-bottom: 10px;
		width: calc(33.33% - 10px);
	}

	.product__details__last__option h5:before {
		width: 280px;
	}

	.product__details__cart__option .quantity {
		margin-right: 0;
		margin-bottom: 15px;
	}

	.product__details__last__option h5 span {
		font-size: 16px;
	}

	.blog__hero__text h2 {
		font-size: 36px;
	}

	.categories__text h2 {
		font-size: 30px;
		line-height: 55px;
	}

	.categories__text:before {
		height: 250px;
	}
}

.spacial-controls .search-switch {
	display: block;
	height: 100%;
	padding-top: 30px;
	background: #323232;
	text-align: center;
	cursor: pointer;
}

.search-model {
	display: none;
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background: #000;
	z-index: 99999;
}

.search-model-form {
	padding: 0 15px;
}

.search-model-form input {
	width: 500px;
	font-size: 40px;
	border: none;
	border-bottom: 2px solid #333;
	background: 0 0;
	color: #999;
}

.search-close-switch {
	position: absolute;
	width: 50px;
	height: 50px;
	background: #333;
	color: #fff;
	text-align: center;
	border-radius: 50%;
	font-size: 28px;
	line-height: 28px;
	top: 30px;
	cursor: pointer;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.search-modal .modal-content {
  background-color: #f8f9fa;
  border-radius: 10px;
  border: none;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.search-modal-header {
  background-color: #343a40;
  color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.search-modal-body {
  padding: 20px;
}

.search-modal-footer {
  border-top: none;
}

.search-component .search-input {
  border-radius: 5px;
  border: 1px solid #343a40;
  color: #343a40;
  background-color: #ffffff;
}

.search-component .suggestion-list {
  position: absolute;
  width: 100%;
  z-index: 1000;
  background-color: #ffffff;
  border: 1px solid #343a40;
  border-radius: 5px;
}

.search-component .suggestion-item {
  padding: 0.5rem;
  border: none;
  border-bottom: 1px solid #ddd;
  background-color: #ffffff;
  transition: background-color 0.2s, color 0.2s;
}

.search-component .suggestion-item:last-child {
  border-bottom: none;
}

.search-component .suggestion-link {
  text-decoration: none;
  color: #343a40;
  display: flex;
  align-items: center;
}

.search-component .suggestion-image {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 5px;
}

.search-component .suggestion-name {
  font-weight: bold;
}

.search-component .suggestion-code {
  font-size: 0.875rem;
  color: #666;
}

.search-component .suggestion-item:hover {
  background-color: #343a40;
  color: #ffffff;
}

.search-component .suggestion-item:hover .suggestion-link {
  color: #ffffff;
}
</style>
