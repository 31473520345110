<template>
    <div class="search-component">
      <input
        type="text"
        class="form-control search-input"
        :placeholder="t('Search for products')+'...'"
        v-model="query"
        @input="onInput"
      />
      <ul v-if="suggestions.length" class="list-group mt-2 suggestion-list">
        <li
          v-for="suggestion in suggestions"
          :key="suggestion.id"
          class="list-group-item suggestion-item"
        >
          <a :href="'/product/' + suggestion.id" class="suggestion-link d-flex align-items-center">
            <img :src="'http://127.0.0.1:8000/storage/'+suggestion.picture" alt="Product Image" class="suggestion-image me-3" />
            <div>
              <div class="suggestion-name">{{ suggestion.name }}</div>
              <div class="suggestion-code">({{ suggestion.code }})</div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { debounce } from 'lodash';
  import { API_URL } from '@/services/apis';
import { useI18n } from 'vue-i18n';
  
  export default {
    name: 'SearchComponent',
    setup() {
        const { t } = useI18n();
        return {
            t
        };
    },
    data() {
      return {
        query: '',
        suggestions: []
      };
    },
    methods: {
      onInput: debounce(async function() {
        if (this.query.length > 2) {
          try {
            const response = await axios.get(`${API_URL}/search/products/getproduit?search=${this.query}`);
            this.suggestions = response.data.data;
          } catch (error) {
            console.error('Error fetching product suggestions:', error);
          }
        } else {
          this.suggestions = [];
        }
      }, 300) // Adjust the debounce time as needed
    }
  };
  </script>
  
  <style scoped>
  .search-component {
    position: relative;
  }
  
  .search-input {
    border-radius: 0;
    border: 1px solid #000;
    color: #000;
    background-color: #fff;
  }
  
  .suggestion-list {
    position: absolute;
    width: 100%;
    z-index: 1000;
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 0;
  }
  
  .suggestion-item {
    padding: 0.5rem;
    border: none;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
    transition: background-color 0.2s, color 0.2s;
  }
  
  .suggestion-item:last-child {
    border-bottom: none;
  }
  
  .suggestion-link {
    text-decoration: none;
    color: #000;
    display: flex;
    align-items: center;
  }
  
  .suggestion-image {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 0.25rem;
  }
  
  .suggestion-name {
    font-weight: bold;
  }
  
  .suggestion-code {
    font-size: 0.875rem;
    color: #666;
  }
  
  .suggestion-item:hover {
    background-color: #000;
    color: #fff;
  }
  
  .suggestion-item:hover .suggestion-link {
    color: #fff;
  }
  </style>
  