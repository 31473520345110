<template>
    <ContactPage />
</template>
<script>
import ContactPage from '../components/contact/ContactPage.vue'
export default {
  name: 'ContactView',
  components:{
    ContactPage
  }
}
</script>