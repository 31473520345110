<template>
    <div class="container my-5">
      <div v-if="loading" class="text-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div v-if="error" class="alert alert-danger" role="alert">
        {{ error }}
      </div>
      <div v-if="post" class="post-details">
        <h1 class="text-center mb-4">{{ post.title }}</h1>
        <span><i class="fa fa-pencil" title="Author"></i> <b>LynxTeam</b> | at {{ formattedDate(post.created_at) }}</span>
        <img :src="post.imageUrl" class="img-fluid mb-4" alt="Post Image">
        <div v-html="post.content" class="post-content"></div>
      </div>
    </div>
  </template>
  
  <script>
  import { API_URL } from '@/services/apis';
  import moment from 'moment';
  export default {
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        post: null,
        loading: true,
        error: null,
      };
    },
    mounted() {
      this.fetchPost();
    },
    methods: {
      async fetchPost() {
        try {
          const response = await fetch(`${API_URL}/out/blog/${this.id}`);
          if (!response.ok) {
            throw new Error('Failed to fetch post');
          }
          const data = await response.json();
          this.post = {
            ...data.data,
            imageUrl: `https://picsum.photos/800/400?random=${data.id}`,
          };
        } catch (error) {
          this.error = error.message;
        } finally {
          this.loading = false;
        }
      },
      formattedDate(date) {
        return moment(date).format('MMMM Do YYYY, h:mm:ss a');
      },
    },
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 800px;
  }
  
  .img-fluid {
    width: 100%;
    height: auto;
  }
  
  .post-content {
    color: #333;
  }
  
  .spinner-border {
    color: #000;
  }
  </style>
  