<template>
    <!-- Hero Section Begin -->
    <TrackPage />
    <!-- Hero Section End -->
  </template>
  
  <script>
import TrackPage from '../components/tracking/TrackPage.vue';

  // @ is an alias to /src
 
  export default {
    name: 'TrackView',
    components:{
        TrackPage
    }
  }
  </script>
  <style scoped>
  </style>