<template>
    <TermsPage />
  </template>
  <script>
import TermsPage from '@/components/pages/TermsPage.vue'

  export default {
  name: 'TermsView',
  components:{
    TermsPage
  }
  }
  </script>