<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 ">
                <div class="checkout__order">
                    <h4 class="order__title">Password Reset</h4>
                    <div class="mb-3 register-info">
                        <h5>If you don't have an account you can create account
                            <b class="text-primary">
                                <a href="/register">Register</a></b>
                        </h5>
                    </div>
                    <b class="alert alert-warning text-danger" v-if="message">{{ message }}</b>
                    <form @submit.prevent="requestResetLink">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="checkout__input">
                                    <p>Email<span>*</span></p>
                                    <input type="email" class="form-control" id="email" v-model="email"
                                        placeholder="Email" required />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 text-center"><button type="submit" class="site-btn"
                                    :disabled="loading">Send Reset Link</button></div>
                        </div>
                        <div v-if="error" class="alert alert-danger mt-3">{{ error }}</div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { API_URL } from '@/services/apis';

export default {
    data() {
        return {
            email: '',
            message: '',
        };
    },
    methods: {
        async requestResetLink() {
            try {
                const response = await axios.post(API_URL + '/password/email', { email: this.email });
                this.message = response.data.message;
            } catch (error) {
                this.message = error.response.data.message;
            }
        },
    },
};
</script>