<template>
    <section class="shopping-cart spad">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="shopping__cart__table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in whishlistItems" :key="index">
                                    <td class="product__cart__item">
                                        <div class="product__cart__item__pic">
                                            <img :src="'http://127.0.0.1:8000/storage/' + item.picture" alt=""
                                                style="width:100px;height:100px;">
                                        </div>
                                        <div class="product__cart__item__text">
                                            <h6>{{ item.name }}</h6>
                                            <h5>{{ item.prix }} DH</h5>
                                        </div>
                                    </td>
                                    <td class="cart__close"><i class="fa fa-close" @click="removeFromWhishlist(item.id)"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="continue__btn">
                                <a href="/">Continue Shopping</a>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="continue__btn update__btn">
                                <a href="#" @click="clearWhishlist"><i class="fa fa-spinner"></i> Clear Whishlist</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'WhishlistPage',
  setup() {
    const store = useStore();
    const whishlistItems = computed(() => store.getters.whishlistItems);
    const whishlistCount = computed(() => store.getters.whishlistCount);

    const removeFromWhishlist = (productId) => {
      store.dispatch('removeFromWhishlist', productId);
    };
    const clearWhishlist = () => {
      store.dispatch('clearWhishlist');
    };
    return {
      whishlistItems,
      whishlistCount,
      removeFromWhishlist,
      clearWhishlist
    };
  },
};
</script>

<style scoped>
.pro-qty-2 {
    display: flex;
    align-items: center;
}

.pro-qty-2 button {
    margin: 0 5px;
}
</style>
