<template>
    <section class="shopping-cart spad">
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <div class="shopping__cart__table">
                        <table>
                            <thead>
                                <tr>
                                    <th>{{$t('Product')}}</th>
                                    <th>{{$t('Quantity')}}</th>
                                    <th>{{$t('Total')}}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in cartItems" :key="index">
                                    <td class="product__cart__item">
                                        <div class="product__cart__item__pic">
                                            <img :src="STORAGE_URL+'/' + item.picture" alt=""
                                                style="width:100px;height:100px;">
                                        </div>
                                        <div class="product__cart__item__text">
                                            <h6>{{ item.name }}</h6>
                                            <h6 class="text-danger alert alert-warning"
                                                v-if="item.quantity >= item.totalQty">You can't add more products in
                                                cart our stock have only {{ item.totalQty }} PCS</h6>
                                            In Stock : {{ item.totalQty }} Pcs
                                            <h5>{{ item.prix }} DH</h5>

                                        </div>
                                    </td>
                                    <td class="quantity__item">
                                        <div class="quantity">
                                            <div class="pro-qty-2">
                                                <button class="btn btn-danger" @click="decreaseQuantity(item.id)"
                                                    style="border-radius: 50px;">-</button>
                                                <input type="text" min="1" :max="item.totalQty"
                                                    class="form-control form-control-lg text-center"
                                                    v-model.number="item.quantity"
                                                    @change="updateQuantity(item.id, item.quantity)" disabled>
                                                <span class="btn btn-success" @click="increaseQuantity(item.id)"
                                                    style="border-radius: 50px;">+</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="cart__price">{{ item.prix * item.quantity }} DH</td>
                                    <td class="cart__close"><i class="fa fa-close" @click="removeFromCart(item.id)"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="continue__btn">
                                <a href="/">{{$t('Continue Shopping')}}</a>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="continue__btn update__btn">
                                <a href="#" @click="clearCart"><i class="fa fa-spinner"></i> {{$t('Clear cart')}}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="cart__discount">
                        
                        <div v-if="errorMessages?.length" class="alert alert-danger" role="alert">
                            <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Warning:">
                                <use xlink:href="#exclamation-triangle-fill" />
                            </svg>
                            <ul>
                                <span v-for="(message, index) in errorMessages" :key="index">
                                    <i class="bi bi-exclamation-circle-fill"></i> {{ message }}<br>
                                </span>
                            </ul>
                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>
                        <div v-if="errorText > 0">
                            <span class="text-danger" v-if="errorText == 1">Coupon Invalid</span>
                            <span class="text-danger" v-if="errorText == 2">Copon aleardy used</span>
                            <span class="text-danger" v-if="errorText == 3">Something went Wrong ! Try again</span>
                            <span class="text-success" v-if="errorText == 4">Coupon added ^^</span>
                        </div>
                        <!--<h6>Discount codes</h6><form @submit.prevent="couponCheck">
                            <input type="text" placeholder="Coupon code" class="form-control" name="coupon"
                                v-model="couponValueData.coupon">
                            <button type="submit" class="btn btn-primary">Apply</button>
                        </form>-->
                    </div>
                    <div class="cart__total">
                        <h6>{{$t('Cart total')}}</h6>
                        <ul>
                            <li>{{$t('Subtotal')}} <span>{{ cartTotal }} DH</span></li>
                            
                            <li v-if="discountCoupon.discount > 0 && errorText == 4">{{ $t('Discount') }}<span>{{ discountCoupon.discount }} %</span> </li>
                            <li >{{$t('Total')}} 
                                <span v-if="discountCoupon.discount > 0 && errorText == 4">{{ cartTotal - (cartTotal * (discountCoupon.discount/100)) }} DH</span>
                                <span v-else>{{ cartTotal }} DH</span>
                            </li>
                        </ul>
                        <a href="/checkout" class="btn btn-primary w-100">{{$t('Proceed to checkout')}}</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { API_URL,STORAGE_URL } from '@/services/apis';

export default {
  name: 'CartPage',
  setup() {
    const { t } = useI18n();
    const store = useStore();

    const cartItems = computed(() => store.getters.cartItems);
    const cartTotal = computed(() => store.getters.cartTotal);

    const updateQuantity = (productId, quantity) => {
      store.dispatch('updateCartQuantity', { productId, quantity });
    };

    const removeFromCart = (productId) => {
      store.dispatch('removeFromCart', productId);
    };
    const increaseQuantity = (productId) => {
      const item = cartItems.value.find(item => item.id === productId);
      if (item) {
        if(item.quantity < item.totalQty){
            store.dispatch('updateCartQuantity', { productId, quantity: item.quantity + 1 });
        }
      }
    };

    const decreaseQuantity = (productId) => {
        //console.log('here!')
      const item = cartItems.value.find(item => item.id === productId);
      if (item && item.quantity > 1) {
        store.dispatch('updateCartQuantity', { productId, quantity: item.quantity - 1 });
      }
    };
    const clearCart = () => {
      store.dispatch('clearCart');
    };

    //coupon
    const errorMessages = ref([]);
    const couponValueData = ref({
            coupon: ''
        });
    const discountCoupon = ref(0)
    const errorText = ref(0)
      const validateFormCoupon = () => {
          errorMessages.value = [];
          const requiredFields = ['coupon'];

          for (const field of requiredFields) {
              if (!couponValueData.value[field]) {
                  errorMessages.value.push(`The field ${field.replace('_', ' ')} is required  .`);
              }
          }
          return errorMessages.value.length === 0;
      };
    const couponCheck = () => {
            if (!validateFormCoupon()) {
                return;
            }
            addCoupon()
        }
      const addCoupon = async () => {
            const token = localStorage.getItem('token');
            //console.log(token)
            const couponData = {
                details: couponValueData.value
            };
          try {
                const response = await fetch(API_URL+'/commande/coupon/verify', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(couponData)
                });
              const data = await response.json();
              //console.log(data)
              if (data.success) {
                  
                  errorText.value = 4
                  discountCoupon.value = data.data
              } else {
                  if (data.data.error === 'Coupon Invalid') {
                      errorText.value = 1;
                  } else if (data.data.error === 'Coupon aleardy used') {
                      errorText.value = 2;
                  } else {
                      errorText.value = 3;
                  }
              }

            } catch (error) {
                //console.log(error.data.error)
                if(error.data.error === 'Coupon Invalid'){
                    errorText.value = 1;
                }else if(error.data.error === 'Coupon aleardy used'){
                    errorText.value = 2;
                }else{
                    errorText.value = 3;
                }
                console.error('Error placing order:', error.message);
            }
      };
    return {
      cartItems,
      cartTotal,
      updateQuantity,
      removeFromCart,
      increaseQuantity,
      decreaseQuantity,
      clearCart,
      t,
      addCoupon,
      couponCheck,
      couponValueData,
      errorMessages,
      errorText,
      discountCoupon,
      STORAGE_URL
    };
  },
};
</script>

<style scoped>
.pro-qty-2 {
    display: flex;
    align-items: center;
}

.pro-qty-2 button {
    margin: 0 5px;
}
</style>
