<template>
    <div class="container my-5">
        <div class="row">
            <div class="col-lg-12">
                <div class="about__pic">
                    <img src="../../assets/img/hero/men-2.jpg" alt="">
                </div>
            </div>
        </div>
        <h3 class="text-center mb-4">Our Blog</h3>
        <div v-if="loading" class="text-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div v-if="error" class="alert alert-danger" role="alert">
            {{ error }}
        </div>
        <div v-if="posts.length" class="row">
            <div class="col-md-4 mb-4" v-for="post in paginatedPosts" :key="post.id">
                <a :href="'post/'+post.id">
                    <div class="card h-100 text-white bg-dark border-light">
                        <img src="http://127.0.0.1:8082/img/men-2.d11b2aa7.jpg" class="card-img-top" alt="Post Image">
                        <div class="card-body">
                            <h5 class="card-title">{{ post.title }}</h5>
                        </div>
                    </div>
                </a>
            </div>
        </div>
        <nav v-if="posts.length" class="d-flex justify-content-center">
            <ul class="pagination">
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                    <button class="page-link" @click="changePage(currentPage - 1)">Previous</button>
                </li>
                <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
                    <button class="page-link" @click="changePage(page)">{{ page }}</button>
                </li>
                <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                    <button class="page-link" @click="changePage(currentPage + 1)">Next</button>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
import { API_URL } from '@/services/apis';

export default {
    data() {
        return {
            posts: [],
            loading: true,
            error: null,
            currentPage: 1,
            postsPerPage: 20,
        };
    },
    computed: {
        totalPages() {
            return Math.ceil(this.posts.length / this.postsPerPage);
        },
        paginatedPosts() {
            const start = (this.currentPage - 1) * this.postsPerPage;
            const end = start + this.postsPerPage;
            return this.posts.slice(start, end);
        },
    },
    mounted() {
        this.fetchPosts();
    },
    methods: {
        async fetchPosts() {
            try {
                const response = await fetch(API_URL + '/out/blog/index');
                if (!response.ok) {
                    throw new Error('Failed to fetch posts');
                }
                let data = await response.json();
                data = data.data
                    .map(post => ({
                        ...post,
                        created_at: new Date(Date.now() - Math.floor(Math.random() * 10000000000)).toISOString(),
                        imageUrl: `https://picsum.photos/200/300?random=${post.id}`,
                        processedContent: this.processContent(post.content),
                    }))
                    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                this.posts = data;
            } catch (error) {
                this.error = error.message;
            } finally {
                this.loading = false;
            }
        },
        changePage(page) {
            if (page > 0 && page <= this.totalPages) {
                this.currentPage = page;
            }
        },
        processContent(content) {
            // Create a temporary element to manipulate the content
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = content;

            // Remove leading images
            while (tempDiv.firstChild && tempDiv.firstChild.tagName === 'IMG') {
                tempDiv.removeChild(tempDiv.firstChild);
            }

            // Return the processed inner HTML
            return tempDiv.innerHTML;
        },
    },
};
</script>

<style scoped>
.container {
    max-width: 1200px;
}

.card-title {
    color: #ffffff;
}

.card-text {
    color: #d3d3d3;
}

.spinner-border {
    color: #000;
}

.card-img-top {
    height: 200px;
    object-fit: cover;
}

.pagination .page-link {
    color: #000;
}

.pagination .page-link:hover {
    color: #000;
}

.pagination .page-item.active .page-link {
    background-color: #000;
    border-color: #000;
    color: #fff;
}

.content-preview {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>