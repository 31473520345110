<template>
    <section class="about spad">
          <div class="container">
              <div class="row">
                  <div class="col-lg-12">
                      <div class="about__pic">
                          <img src="../../assets/img/hero/men-1.jpg" alt="">
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="about__item text-center">
                          <h4 class="">The LYNX Story</h4>
                          <p >In the sun-drenched landscapes of Morocco, where the vibrant culture meets the timeless charm of ancient cities, two cousins embarked on a journey that would blend tradition with innovation. In 2024, LYNX was born, a beacon of style and vision in the world of eyewear.</p>
                          <p>The idea for LYNX sprouted from a shared passion between Hafid and Oualid, 
                            cousins with a deep appreciation for fashion and functionality. Growing up in the bustling streets of Rabat Morocco, 
                            they were inspired by the rich tapestry of colors, patterns, and craftsmanship that surrounded them. Both avid travelers
                            and keen observers of the latest trends, they noticed a gap in the market for high-quality, 
                            stylish eyewear that also paid homage to their Moroccan heritage.</p>
                          <p>HAFID, with his background in design, and OUALID, an optometrist by profession, combined their skills and vision to 
                            create something unique. They envisioned glasses that were not just accessories but expressions of individuality 
                            and cultural pride. They wanted every pair of LYNX glasses to tell a story, 
                            to embody the spirit of Morocco, and to connect people with the essence of its beauty.</p>
                         <p>From the very beginning, LYNX has been committed to excellence. 
                            Every pair of sunglasses and optical glasses is meticulously designed and crafted using the finest materials. 
                            The frames are inspired by the intricate mosaics of Moroccan art, the curves of its architecture, 
                            and the natural beauty of its landscapes. Each piece reflects the vibrant energy of Morocco, 
                            blending contemporary aesthetics with traditional elegance.</p>
                        <p>What sets LYNX apart is not just its distinctive designs, but also its dedication to quality and 
                            comfort. HAFID and OUALID personally oversee the production process, ensuring that every pair meets 
                            the highest standards of durability and clarity. Whether it's the UV protection in our sunglasses 
                            or the precision lenses in our optical glasses, we prioritize your vision and style.</p>
                        <p>Our journey from a small workshop in Morocco to a brand recognized globally is a testament to our passion*
                            and the support of our loyal customers. At LynxBrand, we believe that eyewear should be more than just 
                            a necessity; it should be an extension of who you are, a reflection of your journey and your unique 
                            perspective on the world.</p>
                        <p>Join us in seeing the world through a new lens. Discover LYNX, 
                            where tradition meets innovation, and let your eyes tell your story.</p>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <section class="testimonial">
          <div class="container-fluid">
              <div class="row">
                  <div class="col-lg-6 p-0">
                      <div class="testimonial__text">
                          <span class="icon_quotations"></span>
                          <p>“Heading out after a long day at work? Slip on your LYNX sunglasses or switch to your stylish optical glasses before stepping out, and make a seamless transition from the office to your evening plans without missing a beat.”
                          </p>
                          <div class="testimonial__author">
                             
                              <div class="testimonial__author__text">
                                  <p>LYNX Founders</p>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-6 p-0">
                      <div class="testimonial__pic set-bg" :style="{ backgroundImage: `url(https://lynxlook.com/assets/img/hero/women-5.jpg)` }"></div>
                  </div>
              </div>
          </div>
      </section>
      
  </template>
<script>
import { STORAGE_URL } from '@/services/apis';
export default {
name: 'AboutPage',
computed: {
        storageUrl() {
            return STORAGE_URL;
        },
}
}
</script>  