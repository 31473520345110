<template>
    <ul>
        <li v-for="menu in menus" :key="menu.id" :class="{ active: menu.id === activeMenuId }">
            <a :href="GLOBAL_URL+'/'+menu.path" @click="setActiveMenu(menu.id)">{{ t(menu.menu_name) }}</a>
        </li>
    </ul>
</template>

<script>
import { API_URL,GLOBAL_URL } from '@/services/apis';
import axios from 'axios';
import { useI18n } from 'vue-i18n';
import { ref, onMounted } from 'vue';

export default {
    name: 'MenuView',
    setup() {
        const { t } = useI18n();
        const menus = ref([]);
        const activeMenuId = ref(null);
        const error = ref(null);
        const loading = ref(true);

        const fetchMenu = async () => {
            try {
                const response = await axios.get(API_URL + '/settings/menus/global/index');
                menus.value = response.data.data;
            } catch (err) {
                error.value = 'Failed to load products';
                console.error(err);
            } finally {
                loading.value = false;
            }
        };

        const setActiveMenu = (id) => {
            activeMenuId.value = id;
        };

        onMounted(fetchMenu);

        return {
            menus,
            t,
            error,
            loading,
            activeMenuId,
            setActiveMenu,
            GLOBAL_URL
        };
    }
}
</script>